<template>
    <div>
        <!--我的套餐-->
        我的套餐先隐藏, 接口已经对接完毕, 后期只要把 vuex 内的数据渲染出来即可
    </div>
</template>

<script>
export default {
    created() {
        // 如果是登录状态测获取我的套餐
        if (localStorage.phone) {
            this.$store.dispatch('getMyPackages') // 登录后获取我的套餐
        }
    },
}
</script>

<style scoped>

</style>
