const seoData = {
    default: {
        title: '卖家工具箱-旺旺照妖镜 黑号查询 旺旺强行打标 关键词卡首屏 生意参谋插件 淘宝卖家工具箱',
        keywords: '旺旺照妖镜,黑号查询,旺旺强行打标,买家打标,淘宝卡首屏,关键词卡首屏,淘宝信誉查询,淘宝小号查询,买家信誉查询,关键词排名查询,生意参谋指数转换,黑搜工具,淘宝工具,淘宝卖家工具',
        description: '一站式卖家工具平台！免费提供淘宝买家信誉查询、旺旺照妖镜、关键词排名查询、淘宝卡首屏、旺旺强行打标等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱。'
    },
    defaultClone: {
        title: '卖家工具箱-旺旺照妖镜 黑号查询 旺旺强行打标 关键词卡首屏 生意参谋插件 淘宝卖家工具箱',
        keywords: '旺旺照妖镜,黑号查询,旺旺强行打标,买家打标,淘宝卡首屏,关键词卡首屏,淘宝信誉查询,淘宝小号查询,买家信誉查询,关键词排名查询,生意参谋指数转换,黑搜工具,淘宝工具,淘宝卖家工具',
        description: '一站式卖家工具平台！免费提供淘宝买家信誉查询、旺旺照妖镜、关键词排名查询、淘宝卡首屏、旺旺强行打标等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱。'
    },
    'home': {
        title: 'V兔卖家工具箱-旺旺照妖镜 黑号查询 旺旺强行打标 关键词卡首屏 生意参谋插件 淘宝卖家工具箱',
        keywords: '旺旺照妖镜,黑号查询,旺旺强行打标,买家打标,淘宝卡首屏,关键词卡首屏,淘宝信誉查询,淘宝小号查询,买家信誉查询,关键词排名查询,生意参谋指数转换,黑搜工具,淘宝工具,淘宝卖家工具,V兔卖家工具,vv-tool',
        description: 'V兔卖家工具(vv-tool.com)：一站式卖家工具平台！免费提供淘宝买家信誉查询、旺旺照妖镜、关键词排名查询、淘宝卡首屏、旺旺强行打标等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱。'
    },
    'weightpush': {
        title: '搜索权重推送 手淘权重推送 推送权重词 推送搜索旺旺号 首页搜索结果推送',
        keywords: '搜索权重推送，手淘权重推送，推送权重词，推送搜索旺旺号，首页搜索结果推送',
        description: 'V兔卖家工具(vv-tool.com)：给指定淘宝商品、指定关键词、指定淘宝买家旺旺搜索权重推送，推送后宝贝出现在手淘搜索页前20位，可实现宝贝精准推送,搜索卡首屏的效果。'
    },
    'marking': {
        title: '旺旺强行打标 淘宝搜索打标 旺旺搜索打标 搜索卡首屏淘宝足迹推送 淘宝魔搜- V兔卖家工具箱',
        keywords: '旺旺强行打标,淘宝搜索打标,旺旺搜索打标,搜索卡首屏,淘宝足迹推送,淘宝魔搜工具,旺旺标签透视',
        description: 'V兔卖家工具(vv-tool.com)：给指定淘宝商品、指定关键词、指定淘宝买家旺旺打标，打标后宝贝出现在手淘搜索页前20位，可实现宝贝精准推送,搜索卡首屏的效果。'
    },
    'transformData': {
        title: '生意参谋指数还原工具 生意参谋指数转换 生意参谋指数查询 生意参谋插件- V兔卖家工具箱',
        keywords: '旺旺强行打标,买家强行打标,淘宝搜索打标,旺旺搜索打标,搜索卡首屏,淘宝足迹推送,淘宝魔搜工具',
        description: 'V兔卖家工具(vv-tool.com)：生意参谋查指数工具为淘宝天猫卖家提供生意参谋指数还原、流量指数、转化率指数、交易等指数计算换算服务，方便淘宝商家直观看到自己和竞争对手的店铺数据。'
    },
    'tbguest': {
        title: '淘客订单检测 淘宝客订单检测 杜绝刷手淘宝客链接下单- V兔卖家工具箱',
        keywords: '淘宝客订单检测,防止刷手淘宝客下单,判断是否淘宝客下单,淘客订单检测',
        description: 'V兔卖家工具(vv-tool.com)：根据淘宝订单号进行淘宝客订单检测,可以彻底杜绝刷手淘宝客下单,避免佣金损失，淘宝卖家必备工具箱'
    },
    'checkNum/dsrCal': {
        title: '淘宝动态评分查询 动态评分计算器 淘宝DSR评分计算 天猫动态评分计算器- V兔卖家工具箱',
        keywords: '店铺动态评分查询,淘宝动态评分查询,淘宝宝贝动态评分查询,宝贝动态评分计算器,计算店铺动态评分,动态评分计算器,淘宝DSR评分计算,天猫动态评分计算器',
        description: 'V兔卖家工具(vv-tool.com)：输入当前DSR基础数据与目标数据，自动计算还需要多少单5分的好评！店铺单量目标一目了然，淘宝卖家必备工具箱。'
    },
    'keyRank': {
        title: '淘宝宝贝排名 天猫宝贝排名 直通车排名 淘宝关键词排名分析 京东关键词排名查询- V兔卖家工具箱',
        keywords: '淘宝宝贝排名在线查询,宝贝排名查询,快速查询宝贝排名,淘宝无线端宝贝排名查询,关键词排名分析',
        description: 'V兔卖家工具(vv-tool.com)：根据提交的淘宝关键词查询该关键词相关宝贝排名,淘宝推荐属性词,宝贝数量等信息。'
    },
    'store/list': {
        title: '礼品批发 小礼品商城 礼品网 电商礼品网真实礼品批发平台- V兔卖家工具箱',
        keywords: '轻小件、礼品网、单号网、礼品商城、礼品网站、菜鸟礼品批发、真实快递包裹、电商真实礼品、真实礼品批发平台、电商礼品网',
        description: 'V兔卖家工具(vv-tool.com)：专业淘宝拼多多等电商礼品批发平台，全国顺丰、圆通、申通、邮政等仓库发货网站提供菜鸟面单京东无界电子面单,真实打单、派送、签收，为商家降低快递、人力成本，减少库存压力，节省时间，提交效率。'
    },
    'flow/taobao': {
        title: '淘宝搜索流量提升 收藏加购 补量 APP流量 手淘卡屏- V兔卖家工具箱',
        keywords: '淘宝引流,商品收藏,店铺收藏,收藏加购,达人粉丝,淘宝买流量,淘宝手机流量,淘宝流量软件,淘宝流量提升,网店推广软件',
        description: 'V兔卖家工具(vv-tool.com)：模拟人工自然搜索、点击、停留浏览店铺、加购物车、收藏和宝贝PC流量、手机无线APP流量提升，用户可通过买流量快速提升店铺商品搜索排名、展现量、访问量和人气值。'
    },
    'interface': {
        title: 'V兔API市场 旺旺强行打标API 旺旺照妖镜API 淘宝验号API 黑号库API- V兔卖家工具箱',
        keywords: '旺旺强行打标API,旺旺照妖镜API,淘宝验号API,黑号库API ,淘宝查询排名API',
        description: 'V兔卖家工具(vv-tool.com)：提供淘宝各种API接口，旺旺打标接口，旺旺照妖镜接口，淘宝验号接口，黑号库接口等。'
    },
    'vip': {
        title: '卖家工具箱-v兔 搜索标签权重推送 旺旺搜索打标 关键词卡首屏 旺旺标签查询 淘宝卖家工具箱',
        keywords: '旺旺照妖镜,搜索标签权重推送,旺旺搜索打标,买家打标,淘宝卡首屏,关键词卡首屏,足迹推送,v兔,v兔工具,关键词排名查询,生意参谋指数转换,黑搜工具,淘宝工具,淘宝卖家工具,V兔卖家工具,vv-tool',
        description: 'V兔卖家工具(vv-tool.com)：一站式卖家工具平台！免费提供淘宝买家信誉查询、旺旺照妖镜、关键词排名查询、淘宝卡首屏、旺旺强行打标等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱。'
    },
    'lexicon': {
        title: '淘宝直通车TOP20W词库 top20万关键词 TOP20w关键词 淘宝关键词词典- V兔卖家工具箱',
        keywords: '淘宝top20W下载,淘宝top20w关键词,直通车top20W下载地址,top20W词表下载地址,top20万关键词',
        description: 'V兔卖家工具(vv-tool.com)：公布淘宝直通车Top20W关键词表， 这些热销、热搜关键词是直通车后台中，搜索量最好，曝光量最高的一些关键词组合，是直通车车手迅速提升店铺流量的最有力的武器。'
    },
    'lexRecommend': {
        title: 'v兔卖家工具,手淘搜索框关联词推荐,淘宝热词,搜索下拉框关键词查询,搜索下拉框选词工具',
        keywords: '手淘搜索框关键词抓取接口,淘宝搜索下拉框,搜索词推荐,关键词挖掘工具,搜索下拉框选词',
        description: 'V兔卖家工具(vv-tool.com)：手机淘宝下拉框关键词查询,实时查询搜索词关联的推荐词库,实时同步官方词库数据,挖掘淘宝关键词,高流量高转化,高性价比关键词,直通车选词'
    },
    'lexTaobaoTag': {
        title: '淘宝商品标签查询,淘宝用户标签透视,淘宝号标签查询,旺旺标签查询,淘宝号誉实时标签和权重',
        keywords: '用户标签照妖镜接口,旺旺标签属性,淘宝用户标签透视工具,用户画像,用户行为分析',
        description: 'V兔卖家工具(vv-tool.com)：照妖镜标签检测,照妖镜旺旺小号标签查询,查询用户搜索过的淘宝商品标签，通过足迹,购买记录,猜你喜欢推荐的标签词库查询,可以批量查询指定旺旺名最近的搜索'
    },
    'pddScreen': {
        title: '拼多多打标,多多打标，拼多多首页推荐',
        keywords: '拼多多黑科技,首页推荐入口,打标工具,标签神器',
        description: '拼多多打标是在官方千人千面下将指定商品通过首页推荐入口展现给拼多多账号,快速提升商品及店铺权重,精准化店铺人群标签,为后续店铺流量的引入打好基础'
    },
    'foreignTao': {
        title: '洋淘秀卡首屏 洋淘秀做单入口 在线生成买家秀淘口令- V兔卖家工具箱',
        keywords: '在线生成淘口令,生成首屏卡位淘口令,手淘首页淘口令,挑尖货淘口令,热门市场淘口令,生活研究所淘口令,特色好货淘口令,任意链接淘口令列表',
        description: 'V兔卖家工具(vv-tool.com)：生成洋淘秀卡首屏淘口令，快速精准做单做销量。洋淘秀卡首屏，提升手淘卡屏，获取免费公域流量。'
    },
    'flow/pdd': {
        title: '拼多多搜索流量提升 收藏加购 拼多多补量 APP流量 拼多多流量- V兔卖家工具箱',
        keywords: '拼多多引流,商品收藏, 拼多多买流量, 拼多多手机流量, 拼多多流量软件, 拼多多流量提升,网店推广软件',
        description: 'V兔卖家工具(vv-tool.com)：人工自然搜索、点击、停留浏览店铺、加购物车、收藏和宝贝PC流量、手机无线APP流量提升，用户可通过买流量快速提升拼多多店铺商品搜索排名、展现量、访问量和人气值'
    },
    'flow/jd': {
        title: '京东搜索流量提升 收藏加购 京东补量 京东APP流量 京东流量- V兔卖家工具箱',
        keywords: '京东引流,商品收藏, 京东买流量, 京东手机流量, 京东流量软件, 京东流量提升,网店推广软件',
        description: 'V兔卖家工具(vv-tool.com)：人工自然搜索、点击、停留浏览店铺'
    },
    'goodsAnalysis': {
        title: '竞品数据透析,透析竞争对手数据,竞品透析工具,竞品透析,竞品数据，淘宝魔搜- V兔卖家工具箱',
        keywords: '竞品数据透析，商品详情查询，竞品数据可视化，数据采集接口，市场数据洞察，数据一键查询随时自由选择查询是商家店铺运营必备神器。',
        description: '竞品数据透析，是协助商家快速进行数据化分析的工具,同时提供任意商品的访客数、访客数来源、支付买家数、日期、下单件数等。通过大量数据分析，形成一键式分析诊断报表，帮助商家快速解决问题。'
    },
    'traceless': {
        title: '无痕下单,无痕做单高级版,无痕做单技术,无痕做单生成器',
        keywords: '无痕做单代码,无痕下单代码,无痕下单接口,无痕做单接口,无痕做单高级版接口',
        description: '无痕秒单生成器：安全性较高，实测，每天一两百单内非常安全，不计入访客，转化率会超高，甚至超过100%无搜索权重，只累计基础销量权重'
    },
    'traceless/pdd': {
        title: '拼多多无痕做单,拼多多无痕单,无痕做单技术,拼多多无痕破零,拼多多直接扫码下单,V兔卖家工具箱',
        keywords: '拼多多无痕秒单,无痕秒拍工具,无痕订单生成,无痕提销量拉坑产,无痕引流',
        description: '拼多多无痕做单功能提供了用于拼多多平台商品秒单秒拍的工具可实时生成商家所需求无足迹无记录的无痕订单,用于商品提销量拉坑产,快速引爆商品流量'
    },
    'traceless/1688': {
        title: '1688无痕做单,阿里巴巴无痕单,无痕做单技术,1688无痕破零,1688直接扫码下单,V兔卖家工具箱',
        keywords: '阿里巴巴无痕秒单,无痕秒拍工具,无痕订单生成,无痕提销量拉坑产,无痕引流',
        description: '1688无痕做单功能功能提供了用于阿里巴巴平台商品秒单秒拍的工具可实时生成商家所需求无足迹无记录的无痕订单,用于商品提销量拉坑产,快速引爆商品流量'
    },
    'dy-first-page': {
        title: '抖音卡首屏,卡首屏二维码,卡首屏推荐流量,抖音抖店商品卡首屏',
        keywords: '在线生成卡首屏接口,抖音商城卡首屏,活动页卡首屏,商品榜单卡首屏,猜你喜欢卡首屏',
        description: '抖音卡首屏可为抖音商品生成首屏卡位,提供快速卡抖音商品榜推荐入口,提升渠道入口的销量与权重,有效帮助商家破零,拉坑产'
    },
    'push/also-like': {
        title: '猜你喜欢打标,手淘推荐,猜你喜欢接口,猜你喜欢推送,淘宝猜你喜欢入口,手淘猜你喜欢接口,手淘推荐入口,打标猜你喜欢,推送猜你喜欢',
        keywords: '猜你喜欢打标,手淘推荐,猜你喜欢接口,猜你喜欢推送,淘宝猜你喜欢入口,手淘猜你喜欢接口,手淘推荐入口,打标猜你喜欢,推送猜你喜欢',
        description: '提供猜你喜欢的功能和接口，数据显示在生参生意参谋显示【淘内免费】-【手淘推荐】-【其他猜你喜欢】,给指定旺旺通过其他猜你喜欢浏览该商品，提升商品对该用户的权重值'
    },
    'push/mobile-small': {
        title: '微详情打标,猜你喜欢打标,微详情页推送,手淘推荐微详情,微详情流量入口,微详情卡首屏,猜你喜欢推送,微详情首页流量',
        keywords: '手淘首页推荐,微详情打标,猜你喜欢打标,微详情页推送,手淘推荐微详情,微详情流量入口,微详情卡首屏',
        description: '【首页推荐-微详情】推送提供手淘首页推荐微详情的流量入口,给指定旺旺推送宝贝至手淘首页猜你喜欢,获取手淘首页微详情推荐流量,实现宝贝的精准推荐与展现'
    },
    'push/also-like-home': {
        title: '手淘直播打标,直播间推送、直播间访客推送、直播间流量推送、直播间人气推送，直播间精准打标',
        keywords: '直播间权重推送、直播间访客推送、直播间流量推送、直播间人气推送，直播间精准打标',
        description: '提供直播间推送，数据显示在生参后台显示淘宝直播间进入的流量,给指定旺旺推送宝贝至手淘首页淘宝直播页面,获取直播间推荐流量，增加宝贝在直播间的权重'
    },
    'push/also-like-cart': {
        title: '购物车推荐,购物车猜你喜欢打标,购物车流量入口,手淘推荐购物车,购物车商品推荐,购物车猜你喜欢推送，购物车猜你喜欢打标',
        keywords: '购物车猜你喜欢推荐,手淘购物车流量,购物车打标推送,购物车关联推荐,购物车入池推荐',
        description: '给指定旺旺号打标后，在购物车下方出现打标商品或者出现打标的同类商品，生参后台显示手淘推荐-购中推荐，快速获取购物车流量'
    },
    // 手淘推荐
    'head_screen/recommend': {
        title: '手淘推荐卡首屏 首页推荐流量 手淘首页流量 手淘首页 搜索流量 手淘搜索进店-V兔卖家工具箱',
        keywords: '首页推荐流量,手淘首页流量,手淘首页,搜索流量,手淘搜索进店,电商流量工具',
        description: '一站式卖家工具平台！免费提供首页推荐流量,手淘首页流量,手淘首页,搜索流量,手淘搜索进店,电商流量工具等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 有好货入口
    'tbmobile/nicegoods': {
        title: '有好货入口卡首屏  有好货商城 有好货精选 有好货商品池 淘宝有好货 好货网-V兔卖家工具箱',
        keywords: '有好货商城,有好货精选,有好货商品池,淘宝有好货,好货网',
        description: '一站式卖家工具平台！免费提供有好货商城,有好货精选,有好货商品池,淘宝有好货,好货网等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 猜你喜欢
    'tbmobile/guess': {
        title: '猜你喜欢卡首屏 手淘首页-猜你喜欢 手淘购物车-你可能还喜欢 手淘订单列表页-你可能还喜欢 手淘交易成功页-你可能还喜欢 手淘其他-猜你喜欢等渠道流量-V兔卖家工具箱',
        keywords: '手淘首页-猜你喜欢,手淘购物车-你可能还喜欢,手淘订单列表页-你可能还喜欢,手淘交易成功页-你可能还喜欢,手淘其他-猜你喜欢等渠道流量',
        description: '一站式卖家工具平台！免费提供手淘首页-猜你喜欢,手淘购物车-你可能还喜欢,手淘订单列表页-你可能还喜欢,手淘交易成功页-你可能还喜欢,手淘其他-猜你喜欢等渠道流量等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 智钻
    'tbmobile/hbooth': {
        title: '智钻卡首屏 淘宝智钻推广 智钻图 智钻推广图 智钻广告 钻展 智钻三大板块-V兔卖家工具箱',
        keywords: '淘宝智钻推广,智钻图,智钻推广图,智钻广告,钻展,智钻三大板块',
        description: '一站式卖家工具平台！免费提供淘宝智钻推广,智钻图,智钻推广图,智钻广告,钻展,智钻三大板块等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 全球购入口
    'tbmobile/globalshop': {
        title: '全球购入口卡首屏 全球购入驻 全球购官方 全球购商城 入驻全球购-V兔卖家工具箱',
        keywords: '全球购入驻,全球购官方,全球购商城,入驻全球购',
        description: '一站式卖家工具平台！免费提供全球购入驻,全球购官方,全球购商城,入驻全球购等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 每日好店入口
    'tbmobile/dayshops': {
        title: '每日好店入口卡首屏 淘宝每日好店 每日好店必逛好店 首页展示 网店-V兔卖家工具箱',
        keywords: '淘宝每日好店,每日好店必逛好店,首页展示,网店',
        description: '一站式卖家工具平台！免费提供淘宝每日好店,每日好店必逛好店,首页展示,网店等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 淘抢购入口
    'tbmobile/robshop': {
        title: '淘抢购入口卡首屏 淘抢购APP 淘抢购活动 淘抢购商家门户 淘宝限时秒杀入口 淘宝淘抢购-V兔卖家工具箱',
        keywords: '淘抢购APP,淘抢购活动,淘抢购商家门户,淘宝限时秒杀入口,淘宝淘抢购',
        description: '一站式卖家工具平台！免费提供淘抢购APP,淘抢购活动,淘抢购商家门户,淘宝限时秒杀入口,淘宝淘抢购等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 淘金币
    'head_screen/coins': {
        title: '淘金币卡首屏 品牌汇 每天上新 30天最低价再抵钱 币抢购 金币抵钱 淘金币抽奖 折扣兑换 金币充值 店铺街 欢乐谷 游戏厅 金币秒杀-V兔卖家工具箱',
        keywords: '品牌汇,每天上新,30天最低价再抵钱,币抢购,金币抵钱,淘金币抽奖,折扣兑换,金币充值,店铺街,欢乐谷,游戏厅,金币秒杀',
        description: '一站式卖家工具平台！免费提供品牌汇,每天上新,30天最低价再抵钱,币抢购,金币抵钱,淘金币抽奖,折扣兑换,金币充值,店铺街,欢乐谷,游戏厅,金币秒杀等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 生活研究所
    'head_screen/life': {
        title: '生活研究所卡首屏 自然研究所 家庭研究所 生活家 未来生活研究所-V兔卖家工具箱',
        keywords: '自然研究所,家庭研究所,生活家,未来生活研究所',
        description: '一站式卖家工具平台！免费提供自然研究所,家庭研究所,生活家,未来生活研究所等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // IFashion
    'tbmobile/iFashion': {
        title: 'IFashion卡首屏 淘宝IFashion -红神网 淘宝店铺 淘宝卖家 淘宝网-V兔卖家工具箱',
        keywords: '淘宝IFashio,-红神网,淘宝店铺,淘宝卖家,淘宝网',
        description: '一站式卖家工具平台！免费提供淘宝IFashion,-红神网,淘宝店铺,淘宝卖家,淘宝网等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 微详情
    'head_screen/micro-details': {
        title: '微详情卡首屏 猜你喜欢打标 手淘推荐 猜你喜欢接口 手淘直播推荐流量 手淘直播权重-V兔卖家工具箱',
        keywords: '猜你喜欢打标,手淘推荐,猜你喜欢接口,手淘直播推荐流量,手淘直播权重',
        description: '一站式卖家工具平台！免费提供淘宝猜你喜欢打标,手淘推荐,猜你喜欢接口,手淘直播推荐流量,手淘直播权重等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 问大家
    'head_screen/ask-everyone': {
        title: '问大家卡首屏 卖家 转化率 淘宝商家 淘宝问大家 在线问答-V兔卖家工具箱',
        keywords: '卖家,转化率,淘宝商家,淘宝问大家,在线问答',
        description: '一站式卖家工具平台！免费提供卖家,转化率,淘宝商家,淘宝问大家,在线问答等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 亲宝贝入口
    'tbmobile/probaby': {
        title: '亲宝贝入口卡首屏 淘宝品宝贝平台进入 淘宝宝贝 淘宝亲宝贝报名 淘宝亲宝贝报名入口网址-V兔卖家工具箱',
        keywords: '淘宝品宝贝平台进入,淘宝宝贝,淘宝亲宝贝报名,淘宝亲宝贝报名入口网址',
        description: '一站式卖家工具平台！免费提供淘宝品宝贝平台进入,淘宝宝贝,淘宝亲宝贝报名,淘宝亲宝贝报名入口网址等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 购后猜你喜欢
    'head_screen/buy-ask-everyone': {
        title: '购后猜你喜欢卡首屏 猜你喜欢打标 淘宝猜你喜欢入口 猜你喜欢推送 手淘推荐入口 推送猜你喜欢-V兔卖家工具箱',
        keywords: '猜你喜欢打标,淘宝猜你喜欢入口,猜你喜欢推送,手淘推荐入口,推送猜你喜欢',
        description: '一站式卖家工具平台！免费提供猜你喜欢打标,淘宝猜你喜欢入口,猜你喜欢推送,手淘推荐入口,推送猜你喜欢等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 首单优惠
    'head_screen/first-order-discount': {
        title: '首单优惠卡首屏 淘宝首单优惠商家规则 淘宝首单优惠 淘宝首单优惠怎样设置 淘宝卖家首单优惠-V兔卖家工具箱',
        keywords: '淘宝首单优惠商家规则,淘宝首单优惠,淘宝首单优惠怎样设置,淘宝卖家首单优惠',
        description: '一站式卖家工具平台！免费提供淘宝首单优惠商家规则,淘宝首单优惠,淘宝首单优惠设置,淘宝卖家首单优惠等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 芭芭农场
    'head_screen/farm': {
        title: '芭芭农场卡首屏 农场 芭芭多 家庭农场 私家农场 有机农场-V兔卖家工具箱',
        keywords: '农场,芭芭多,家庭农场,私家农场,有机农场',
        description: '一站式卖家工具平台！免费提供淘宝农场,芭芭多,家庭农场,私家农场,有机农场等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 手淘旺旺
    'head_screen/wangwang': {
        title: '手淘旺旺卡首屏 PConline熊掌号 旺旺 阿里手淘 手淘旺信-V兔卖家工具箱',
        keywords: 'PConline熊掌号,旺旺,阿里手淘,手淘旺信',
        description: '一站式卖家工具平台！免费提供PConline熊掌号,旺旺,阿里手淘,手淘旺信等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 手淘其它商品详情页
    'head_screen/other-shop': {
        title: '手淘其它商品详情页卡首屏 淘宝运营 天猫运营 手淘推荐显示商品详情页 手淘网站-V兔卖家工具箱',
        keywords: '淘宝运营,天猫运营,手淘推荐显示商品详情页,手淘网站',
        description: '一站式卖家工具平台！免费提供淘宝运营,天猫运营,手淘推荐显示商品详情页,手淘网站等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 淘内免费其它（通用版）
    'head_screen/other-fee': {
        title: '淘内免费其它(通用版)卡首屏 淘内免费其他流量 淘内免费其他突然暴涨 淘内免费其他权重 淘内免费其他突然猛增流量-V兔卖家工具箱',
        keywords: '淘内免费其他流量,淘内免费其他突然暴涨,淘内免费其他权重,淘内免费其他突然猛增流量',
        description: '一站式卖家工具平台！免费提供淘内免费其他流量,淘内免费其他突然暴涨,淘内免费其他权重,淘内免费其他突然猛增流量等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 小黑盒
    'head_screen/black-box': {
        title: '小黑盒卡首屏 小黑盒功能服务 小黑盒发展历程 小黑盒版本信息 天猫小黑盒-V兔卖家工具箱',
        keywords: '小黑盒功能服务,小黑盒发展历程,小黑盒版本信息,天猫小黑盒',
        description: '一站式卖家工具平台！免费提供小黑盒功能服务,小黑盒发展历程,小黑盒版本信息,天猫小黑盒等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 新品推荐（注：未被收录）
    'head_screen/new-products': {
        title: '新品推荐卡首屏 淘宝爆款 淘宝运营 新品头条 淘宝新品推荐-V兔卖家工具箱',
        keywords: '淘宝爆款,淘宝运营,新品头条,淘宝新品推荐',
        description: '一站式卖家工具平台！免费提供淘宝爆款,淘宝运营,新品头条,淘宝新品推荐等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 相似宝贝
    'checkNum/similarImport': {
        title: '相似宝贝卡首屏 淘宝相似店铺 淘宝找相似 淘宝找同款 淘宝相似宝贝 相似宝贝流量-V兔卖家工具箱',
        keywords: '淘宝相似店铺,淘宝找相似,淘宝找同款,淘宝相似宝贝,相似宝贝流量',
        description: '一站式卖家工具平台！免费提供淘宝相似店铺,淘宝找相似,淘宝找同款,淘宝相似宝贝,相似宝贝流量等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 【高权重】搜索卡首屏
    'head_screen/firstPause': {
        title: '【高权重】搜索卡首屏 关键词卡首屏 淘宝卡首屏 淘宝 关键词搜索 神搜卡首屏-V兔卖家工具箱',
        keywords: '关键词卡首屏,淘宝卡首屏,淘宝,关键词搜索,神搜卡首屏',
        description: '一站式卖家工具平台！免费提供关键词卡首屏,淘宝卡首屏,淘宝,关键词搜索,神搜卡首屏等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 买家秀
    'head_screen/buyers-show': {
        title: '买家秀卡首屏 福利秀 买家秀图片 买家评论 淘宝真实买家秀 买家秀网拍 福利买家秀-V兔卖家工具箱',
        keywords: '福利秀,买家秀图片,买家评论,淘宝真实买家秀,买家秀网拍,福利买家秀',
        description: '一站式卖家工具平台！免费提供福利秀,买家秀图片,买家评论,淘宝真实买家秀,买家秀网拍,福利买家秀等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 天天特卖卡首屏
    'head_screen/daily-deals': {
        title: '天天特卖卡首屏卡 电子商务 淘宝网 淘宝店铺 天天特价-V兔卖家工具箱',
        keywords: '电子商务,淘宝网,淘宝店铺,天天特价',
        description: '一站式卖家工具平台！免费提供电子商务,淘宝网,淘宝店铺,天天特价等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 聚划算品牌特卖
    'head_screen/poly-cost-effective': {
        title: '聚划算品牌特卖卡首屏 聚划算今日特价 今日特价 聚划算淘宝商城 聚划算团购网 聚划算品牌特卖 淘宝聚划算-V兔卖家工具箱',
        keywords: '聚划算今日特价,今日特价,聚划算淘宝商城,聚划算团购网,聚划算品牌特卖,淘宝聚划算',
        description: '一站式卖家工具平台！免费提供聚划算今日特价,今日特价,聚划算淘宝商城,聚划算团购网,聚划算品牌特卖,淘宝聚划算各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 支付页-猜你喜欢-流量来源手淘推荐
    'head_screen/buy-page': {
        title: '支付页-猜你喜欢-流量来源手淘推荐卡首屏 淘宝猜你喜欢流量入口 淘宝运营 手淘 淘猜 手淘首页猜你喜欢-V兔卖家工具箱',
        keywords: '淘宝猜你喜欢流量入口,淘宝运营,手淘,淘猜,手淘首页猜你喜欢',
        description: '一站式卖家工具平台！免费提供淘宝猜你喜欢流量入口,淘宝运营,手淘,淘猜,手淘首页猜你喜欢等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 关键词卡首屏
    'checkNum/firstPause': {
        title: '关键词卡首屏 关键词 淘宝网 淘宝店铺 淘宝卖家 淘宝运营 淘宝推荐-V兔卖家工具箱',
        keywords: '关键词,淘宝网,淘宝店铺,淘宝卖家,淘宝运营,淘宝推荐',
        description: '一站式卖家工具平台！免费提供关键词,淘宝网,淘宝店铺,淘宝卖家,淘宝运营,淘宝推荐等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 旺旺标签权重
    "ww-label-weight": {
        title: '旺旺标签权重 淘宝推荐-V兔卖家工具箱',
        keywords: '旺旺标签权重评估,旺旺号和商品精准匹配,电商流量工具，权重标签信息实时权重标签信息',
        description: '一站式卖家工具平台！免费提供旺旺标签权重评估,旺旺号和商品精准匹配,电商流量工具，权重标签信息实时权重标签信息等各项卖家服务，是淘宝、天猫、京东、拼多多等店铺运营必备工具箱'
    },
    // 抖音搜索卡首屏
    "first-flow-dy/first-flow": {
        title: '抖音搜索卡首屏 抖音卡首屏 抖音流量入口-V兔卖家工具箱',
        keywords: '抖音搜索卡首屏,抖音店群采集选品软件工具,抖音小店店群神器,抖店猜你喜欢采集,专业免费卖家工具,流量来源',
        description: '一站式卖家工具平台！免费提供抖音搜索卡首屏,抖音店群采集选品软件工具,抖音小店店群神器,抖店猜你喜欢采集,专业免费卖家工具,流量来源等各项卖家服务，是淘宝、天猫、京东、拼多多、抖音等店铺运营必备工具箱'
    },
    // 抖音猜你喜欢卡首屏
    "first-flow-dy/guess": {
        title: '抖音猜你喜欢卡首屏 抖音数据分析专家-V兔卖家工具箱',
        keywords: '抖音猜你喜欢卡首屏，抖音数据分析专家,无货源神器,抖店猜你喜欢采集,专业免费卖家工具',
        description: '一站式卖家工具平台！免费提供抖音猜你喜欢卡首屏，抖音数据分析专家,无货源神器,抖店猜你喜欢采集,专业免费卖家工具等各项卖家服务，是淘宝、天猫、京东、拼多多、抖音等店铺运营必备工具箱'
    },
    // 拼多多大家都说好
    "first-flow-pdd/said-ok": {
        title: '拼多多大家都说好卡首屏 拼多多卡首屏-V兔卖家工具箱',
        keywords: '大家都说好卡首屏,免费店铺数据分析,多流量来源,成交关键词,专业免费卖家工具,流量来源',
        description: '一站式卖家工具平台！免费提供大家都说好卡首屏,免费店铺数据分析,多流量来源,成交关键词,专业免费卖家工具,流量来源等各项卖家服务，是淘宝、天猫、京东、拼多多、抖音等店铺运营必备工具箱'
    },
    // 1688卡首屏
    "first-flow-albb": {
        title: '1688关键词卡首屏高级版,关键词卡首屏高级版,1688类目卡首屏,新品搜索卡首屏,1688卡首屏流量,V兔电商工具API',
        keywords: '1688商品卡首屏二维码,1688关键词卡首屏接口,搜索权重卡首屏,1688商品卡首页,卡首屏补精准流量,普通版卡首屏工具',
        description: '1688商品卡首屏二维码，不卡价格不卡发货地只卡商品属性'
    },
    // 拼多多搜索卡首屏
    "first-flow-pdd/first-flow": {
        title: '拼多多搜索卡首屏 拼多多卡首屏-V兔卖家工具箱',
        keywords: '拼多多搜索卡首屏,直通车优化，核心指标,多流量来源,成交关键词,专业免费卖家工具,流量来源',
        description: '站式卖家工具平台！免费提供拼多多搜索卡首屏,直通车优化，核心指标,多流量来源,成交关键词,专业免费卖家工具,流量来源等各项卖家服务，是淘宝、天猫、京东、拼多多、抖音等店铺运营必备工具箱'
    },
}

export const setSeo = to => {
    let mark = 0
    for (const key in seoData) {
        if (to.path.indexOf(key) > -1) {
            seoData[key].title = seoData[key].title.replace(/V兔/g, '').replace(/(vv-tool.com)/g, '__domain').replace(/vv-tool/g, '')
            seoData[key].keywords = seoData[key].keywords.replace(/V兔/g, '').replace(/(vv-tool.com)/g, '__domain').replace(/vv-tool/g, '')
            seoData[key].description = seoData[key].description.replace(/V兔/g, '').replace(/(vv-tool.com)/g, '__domain').replace(/vv-tool/g, '')
            document.title = seoData[key].title
            document.querySelector("meta[name=keywords]").setAttribute('content', seoData[key].keywords)
            document.querySelector("meta[name=description]").setAttribute('content', seoData[key].description)
            mark = 1
        }
    }
    if (!mark) {
        let key = 'defaultClone'
        document.title = seoData[key].title
        document.querySelector("meta[name=keywords]").setAttribute('content', seoData[key].keywords)
        document.querySelector("meta[name=description]").setAttribute('content', seoData[key].description)
    }
}
