<template>
    <div></div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            notify: null,
        }
    },
    computed: {
        ...mapState(['userData', 'configData', 'siderBarCate', 'socket', 'messagePage', 'platformPage', 'platformList']),
    },
    methods: {
        setSocket() {
            if (this.socket) {
                console.log('存在 socket');
                return false;
            }

            this.$store.commit('newWebSocket');

            this.socket.onopen = () => {
                //当WebSocket创建成功时，触发onopen事件
                console.log("open");
                this.socket.send("i am user-a"); //将消息发送到服务端

                // 刚简历 websocket 链接的时候, 请求消息中心数据
                (async () => {
                    await this.getInfo();
                    console.log ('platformList', this.platformList);
                    let platformList = this.platformList;
                    // 得到未读公告列表
                    let noReadList = platformList.filter(item => {
                        // 是公告
                        if (item.push_msg_type && item.push_msg_type == 2) {
                            // 有未读
                            if (item.is_read == 1) { // is_read: 1-未读 2-已读
                                return item;
                            }
                        }
                    })
                    // 有未读公告, 则推出第一条
                    if (noReadList.length) {
                        this.pushNotify(noReadList[0]);
                    }
                })()
            }
            this.socket.onmessage = (e) => {
                if(e.data==='ping'){
                    return
                }
                console.log('e.data', e.data)
                console.log('typeof', typeof e.data);
                //当客户端收到服务端发来的消息时，触发onmessage事件，参数e.data包含server传递过来的数据
                let info = JSON.parse(e.data);

                // 有消息后更新消息数量
                if (info.push_msg_type) {
                    this.getInfo();
                }


                // 通知
                if (info.push_msg_type && info.push_msg_type == 1) {

                }

                // 公告
                if (info.push_msg_type && info.push_msg_type == 2) {
                    this.pushNotify(info);
                }

                // 活动
                if (info.push_msg_type && info.push_msg_type == 3) {

                }
            }
            this.socket.onclose = (e) => {
                //当客户端收到服务端发送的关闭连接请求时，触发onclose事件
                console.log("close");
            }
            this.socket.onerror = (e) => {
                //如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
                console.log(e);
            }
        },

        async getDetail(info) {
            console.log(info);
            this.notify.close && this.notify.close();
            try {
                const res = await this.$fetch('/tool/usersmessages/get-message-detail', {
                    message_id: info.id,
                })
                if (res.code == 0) {
                    // 这里请求详情只是为了告诉后台已读
                }
            } finally {
            }
        },

        // 请求消息中心的数据
        async getInfo() {
            this.$store.commit('setMessagePage', {
                ...this.messagePage,
                page: 1,
            })

            await this.$store.dispatch({
                type: 'getDataMessage',
            })

            this.$store.commit('setPlatformPage', {
                ...this.platformPage,
                page: 1,
            })

            await this.$store.dispatch({
                type: 'getDataPlatform',
            })
        },

        // 推出弹框
        pushNotify(info) {
            this.notify = this.$notify({
                title: info.title,
                dangerouslyUseHTMLString: true,
                message: (
                    <div>
                        <div>
                            <span style="white-space: pre-line;">{info.content}</span>
                            {
                                info.host && (
                                    <a href={info.host} target="_blank">点击查看 <i class="el-icon-document"></i></a>
                                )
                            }
                        </div>
                        <div style={{
                            width: '250px',
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}>
                            {/**
                                <el-button type="text" onClick={() => {
                                    this.getDetail(info);
                                }}>不再提示</el-button>
                            */}
                            <el-button type="text" onClick={() => {
                                // this.notify.close && this.notify.close();
                                // const a = document.createElement('a'); // 创建a标签
                                // a.setAttribute('href', `/newsCenter?push_msg_type=${info.push_msg_type}&id=${info.id}`);// href链接
                                // a.click();// 自执行点击事件
                                location.href = `/newsCenter?push_msg_type=${info.push_msg_type}&id=${info.id}`; 
                                // window.open(`/newsCenter?push_msg_type=${info.push_msg_type}&id=${info.id}`);
                                // this.$router.push({
                                //     path: '/newsCenter',
                                //     query: {
                                //         push_msg_type: info.push_msg_type, // 推送消息类型(1通知2公告3活动)
                                //         id: info.id
                                //     }
                                // })
                            }}>查看全部</el-button>
                            <span>时间: {info.create_time}</span>
                        </div>
                    </div>
                ),
                duration: 0,
            });
        },

    }
}
</script>

<style>
.message-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}
</style>