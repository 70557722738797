import Vue from 'vue'
import App from './App.vue'
import resetPassword from './resetPassword'
import router from './router'
import store from './store'
import cgi from "./plugins/cgi"
import browserHandle from "./plugins/browser"

import './main-config'

Vue.config.productionTip = false

// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.component(CollapseTransition.name, CollapseTransition);

import defaultImg from "./plugins/default_img.js"
Vue.use(defaultImg)

// 仅引入用到的图标以减小打包体积
import 'vue-awesome/icons/flag';
import Icon from 'vue-awesome/components/Icon'
Vue.component('v-icon', Icon)

Vue.use(cgi)

browserHandle();

const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return(false);
}

const isRewriteUrl = getQueryVariable('re')

// 引入外部 js 的方法
Vue.component('remote-script', {
    render: function (createElement) {
        var self = this;
        return createElement('script', {
            attrs: {
                type: 'text/javascript',
                src: this.src
            },
            on: {
                load: function (event) {
                    self.$emit('load', event);
                },
                error: function (event) {
                    self.$emit('error', event);
                },
                readystatechange: function (event) {
                    if (this.readyState == 'complete') {
                        self.$emit('load', event);
                    }
                }
            }
        });
    },
    props: {
        src: {
            type: String,
            required: true
        }
    }
});

new Vue({
    router,
    store,
    render: h => h(isRewriteUrl ? resetPassword : App)
}).$mount('#app')
