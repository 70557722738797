import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState(['configData'])
    },
    async created() {
        // if(Object.keys(this.configData).length == 0 && localStorage.initData == 1) {
        //     localStorage.removeItem('initData')
        // }
        if (Object.keys(this.configData).length == 0) {
            await this.$store.dispatch('getConfig')
        }
    }
}
