<template>
    <div>
        <el-dialog title="活动" :center="true" :visible.sync="dialog" width="760px" :close-on-click-modal="false" @close="closeHandle" top="150px">
            <div>
                <p style="padding-bottom: 12px;">亲爱的用户：</p>
                <p>为回馈新老客户的陪伴与支持，我们为大家精心准备了充值送会员的活动！活动有效期内，不限充值次数！</p>
                <p style="padding-bottom: 12px;">活动内容如下：</p>
                <el-row :gutter="10">
                    <el-col :span="8">
                        <p style="color: #f00;">1.充值100-199元/次,送30天会员</p>
                        <p style="color: #f00;">4.充值500-799元/次,送210天会员</p>
                        <p style="color: #f00;">7.充值1500-1999/次,送390天会员</p>
                    </el-col>
                    <el-col :span="8">
                        <p style="color: #f00;">2.充值200-299元/次,送60天会员</p>
                        <p style="color: #f00;">5.充值800-999元/次,送270天会员</p>
                        <p style="color: #f00;">8.充值2000元以上/次,送420天会员</p>
                    </el-col>
                    <el-col :span="8">
                        <p style="color: #f00;">3.充值300-499元/次,送180天会员</p>
                        <p style="color: #f00;">6.充值1000-1499元/次,送360天会员</p>
                    </el-col>
                </el-row>
                <p style="padding-top: 12px;">活动火热进行中，快来参与吧！</p>
            </div>
            <div slot="footer" class="dialog-footer text-center">
                <el-button type="danger" @click="handleSubmit">参与活动</el-button>
                <el-button @click="closeClick">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        exData: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            dialog: !!this.value,
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        async handleSubmit() {
            this.closeHandle()
            this.goRouter('/recharge')
            this.dialog = false;
        },

        goRouter(path) {
            this.$router.push({
                path,
            })
            this.dialog = false;
        },

        closeClick() {
            this.closeHandle()
            this.dialog = false
        },

        // 关闭的回调
        closeHandle() {
            // 关闭后, 处理下一次弹框的时间为24小时后
            localStorage.setItem("noTipsFive", "true")
            localStorage.setItem("noTipsTimeFive", ((new Date()).valueOf() + 5 * 60 * 60 * 1000) + "")
        },

        goNew() {
            window.open('http://ask.vv-tool.com/article/13680');
        }
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        },
    },
}
</script>

<style lang="scss" scoped>
.top-tip {
    margin-top: -20px;
    margin-bottom: 20px;
}
</style>
