<template>
    <div>
        <el-dialog title="找回密码" :visible.sync="dialog" center width="400px"
            :close-on-click-modal="false" top="50px">
            <div class="bind-phone-dialog">
                <el-form ref="form" :model="form" class="bind-phone">
                    <el-form-item>
                        <!--<el-input v-model.trim="form.phone" :maxlength="11" placeholder="请输入手机号码" @blur="orignCheckPhone"></el-input>-->
                        <el-input v-model.trim="form.phone" placeholder="请输入手机号码" @blur="orignCheckPhone"></el-input>
                    </el-form-item>
                    <el-form-item class="img-code">
                        <el-input placeholder="请输入结果" v-model="form.imgCode">
                        </el-input>
                        <img class="captcha-img" @click="reloadImg"
                            :src="codeImg">
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="请输入验证码" v-model="form.sms_code">
                            <el-button slot="append" class="sendMsg"
                                @click="sendCode" :disabled="isSend || isCheckDisabled">
                                <span>{{btnContent}}</span>
                            </el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="请输入您的密码" v-model="form.password">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="请输入确认密码"
                            v-model="form.ConfirmPassword"
                            @keyup.enter.native="handleSubmit">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer text-center">
                <el-button class="width-100 footer-btn" type="primary"
                    @click="handleSubmit">确认修改</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dialog: !!this.value,
            loading: false,
            time: 0,
            codeImg: '/tool/apps/captcha',
            form: {
                phone: '',
                sms_code: '',
                ConfirmPassword: '',
                imgCode: ''
            },
            btnContent: '获取验证码',
            isCheckDisabled: true,
            isSend: false
        }
    },
    computed: {
        checkPhone() {
            const preg = /^[1][123456789][0-9]{9}$/
            return preg.test(this.form.phone)
        },
        checkImgCode() {
            return this.form.imgCode.length > 0
        },
        checkCode() {
            return !!this.form.sms_code
        },
        checkPassword() {
            const length = this.form.password.length
            return length >= 6 && length <= 18
        },
        checkRepPassword() {
            return this.form.password === this.form.ConfirmPassword
        }
    },
    mounted() {
        this.reloadImg()
    },
    methods: {
        async orignCheckPhone() {
            console.log(this.form.phone);
            if (this.form.phone && this.form.phone.length == 11) {
                const { data, code } = await this.$fetch('/tool/apps/phone-check',{ mobile: this.form.phone, type: 'forget' },'get')
                if (code == 0) {
                    this.isCheckDisabled = false
                }
            }
        },
        async handleSubmit() {
            if (!this.checkPhone) {
                this.$message.error('请填写手机号码!')
                return
            }
            if (!this.checkImgCode) {
                this.$message.error('请填写图形码!')
                return
            }
            if (!this.checkCode) {
                this.$message.error('请填写验证码!')
                return
            }
            if (!this.checkPassword) {
                this.$message.error('密码不能为空, 长度在6-18位之间!')
                return
            }
            if (!this.checkRepPassword) {
                this.$message.error('密码和确认密码不一致!')
                return
            }
            this.loading = true
            const { data, code } = await this.$fetch(
                '/tool/members/forgot',
                { ...this.form, ConfirmPassword: undefined, imgCode: undefined },
                'post'
            )
            this.loading = false
            this.$message.success('重置密码成功')
            this.$store.dispatch('setLogin', data)
            this.$emit('success')
            this.dialog = false
            // if (code === 1001) {
            //     this.reset()
            //     this.$message({
            //         type: 'success',
            //         message: '密码重置成功!'
            //     })
            //     setTimeout(() => {
            //         this.$router.push('/login')
            //     }, 1000)
            // }
        },
        reloadImg() {
            this.codeImg += '?' + Math.random()
        },
        async sendCode() {
            if (this.isSend) return
            //手机号正则验证
            var reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
            if (!this.form.phone) {
                this.$message.error('请输入手机号码')
                return
            }
            if (!reg.test(this.form.phone)) {
                //手机号不合法
                this.$message.error('您输入的手机号码不合法，请重新输入')
            }
            const data = await this.$fetch('/tool/apps/sms', {
                mobile: this.form.phone,
                verify_code: this.form.imgCode,
                type: 'forget'
            })
            if (data.code === 0) {
                this.$message.success('验证码发送成功!')
                this.time = 60
                this.timer()
            } else {
                // this.$message.error(data.msg)
                this.isSend = false
                this.btnContent = '获取验证码'
            }
        },
        timer() {
            if (this.time > 0) {
                this.time--
                this.btnContent = this.time + 's后重新获取'
                this.isSend = true
                window.timer = setTimeout(this.timer, 1000)
            } else if (this.time === 0) {
                this.btnContent = '获取验证码'
                if (window.timer) clearTimeout(window.timer)
                this.isSend = false
            }
            console.log(this.time)
        }
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        },
        'form.phone'(nval, oval) {
            if (nval.length < 11) {
                this.isCheckDisabled = true
            }
        }
    }
}
</script>
<style lang="scss">
.bind-phone-dialog {
    .bind-phone {
        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #666;
            a {
                color: #2b90ed;
            }
        }
        .rules-phone .el-form-item__content {
            margin-left: 10px !important;
            margin-top: -15px;
        }
        .send {
            .el-input-group__append {
                background-color: #aaa;
                border-color: #aaa;
                .el-button.is-disabled:hover {
                    border-color: transparent;
                    background-color: transparent;
                    color: inherit;
                }
            }
        }
        .el-button.is-disabled {
            background: #2b90ed;
            border-color: #2b90ed;
        }
        .el-input-group__append {
            cursor: pointer;
            background-color: #2b90ed;
            border-color: #2b90ed;
            color: #fff;
        }
    }
    div[role='alert'] {
        margin-top: -20px;
        margin-bottom: 20px;
        padding: 5px 10px;
    }
    .captcha-img {
        position: absolute;
        height: 38px;
        right: 1px;
        top: 3px;
    }
}
.footer-btn {
    margin-top: -30px;
}
</style>
