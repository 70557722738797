<template>
    <div class="sidebar-right">
        <div v-if="getCode(headerMenu, basicMenu).includes('header_gmtc')" class="item" @click="$router.push('/buy-package')">
            <img :src="require('@/index/assets/sidebar_right/gmtc.png')" alt="icon" class="icon">
            <p>购买套餐</p>
        </div>
        <div v-if="getCode(headerMenu, basicMenu).includes('header_czhy')" class="item" @click="$router.push('/vip')">
            <img :src="require('@/index/assets/sidebar_right/gmhy.png')" alt="icon" class="icon">
            <p>购买会员</p>
        </div>
        <div class="item" @click="$router.push('/recharge')">
            <img :src="require('@/index/assets/sidebar_right/czdq.png')" alt="icon" class="icon">
            <p>充值点券</p>
        </div>
        <div v-if="getCode(headerMenu, basicMenu).includes('header_dljm')" class="item" @click="$router.push('/agent-join')">
            <img :src="require('@/index/assets/sidebar_right/dljm.png')" alt="icon" class="icon">
            <p>代理加盟</p>
        </div>
        <div class="item" @click="showKf">
            <img :src="require('@/index/assets/sidebar_right/wxkf.png')" alt="icon" class="icon">
            <p>领会员</p>
        </div>
        <div class="item"  @click="showKf">
            <img :src="require('@/index/assets/sidebar_right/qqkf.png')" alt="icon" class="icon">
            <p>领会员</p>
        </div>
        
        <div class="advertise" v-show="advertiseShow">
            <template v-if="configData && Object.keys(configData).length > 0 && 
                (!configData.is_proxy_site || (configData.is_proxy_site && !([87, 305]).includes(parseInt(configData.proxy_info.member_site_id))))" v-cloak>
                <img @click="advertiseClick" class="advertise-img" :src="require('@/index/assets/sidebar_right/advertise.png')" alt="icon" />
                <i @click="advertiseShow = false" class="chacha el-icon-close"></i>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            advertiseShow: true,
            show_ads_img: false,
        }
    },
    computed: {
        ...mapState(["basicMenu", "headerMenu", "configData"])
    },
    methods: {
        advertiseClick(){
            const isWts = window.location.href.indexOf("wangtoushi.cn") > -1
            if (isWts){
                let tempwindow=window.open('_blank')
                tempwindow.location = "https://www.1688yuncang.com/"
            } else {
                this.$router.push('/store/list')
            }
        },

        showKf() {
            this.$emit("showKf")
        },

        // 获取有效的 Code
        getCode(headerMenu = [], basicMenu = []) {
            let result = [];

            headerMenu.forEach(item => {
                result.push(item.showCode)
            })

            basicMenu.forEach(item => {
                result.push(item.showCode)
                if (item.children && item.children.length) {
                    item.children.forEach(cItem => {
                        result.push(cItem.showCode)
                    })
                }
            })

            return result;
        },
    }
}
</script>

<style lang="scss" scoped>
.sidebar-right {
    position: absolute;
    top: 180px;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6px;
        width: 60px;
        height: 50px;
        padding: 5px 0;
        background-color: #f5f5f5;
        border-radius: 4px;
        cursor: pointer;

        img {
            width: 30px;
            height: 30px;
        }

        p {
            font-size: 12px;
            color: #555;
            margin-bottom: 0;
        }
    }

    .advertise {
        position: relative;
        width: 130px;
        cursor: pointer;
        img {
            width: 100%;
        }
        .chacha {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 16px;
            font-weight: 600;
            color: #fff;
            &:hover {
                background-color: rgba(0, 0, 0, .3);
            }
        }
    }
}
</style>
