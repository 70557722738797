<template>
    <div>
        <el-dialog
            title="登录账号"
            :visible.sync="dialog"
            center
            width="400px"
            @close="closeEvent"
            :close-on-click-modal="false"
            top="50px"
            custom-class="login-dialog-content"
        >
            <div class="bind-phone-dialog">
                <el-form ref="form" :model="form" :rules="rules" class="bind-phone">
                    <el-form-item prop="phone">
                        <!--<el-input v-model.trim="form.phone" :maxlength="11" placeholder="请输入手机号码"></el-input>-->
                        <el-input v-model.trim="form.phone" placeholder="请输入手机号码"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input placeholder="请输入密码" type="password" @keyup.enter.native="handleSubmit" v-model="form.password"> </el-input>
                    </el-form-item>
                </el-form>
<!--                分站没有微信登录-->
<!--                <div v-if="isToolMainSite() && showOtherLogin">-->
<!--                    <div class="dividing-line">-->
<!--                        <i></i>-->
<!--                        <span>其他方式登录</span>-->
<!--                    </div>-->
<!--                    <div class="img-content">-->
<!--                        <div class="img-item" title="微信授权登录" @click="weChatLoginClick">-->
<!--                            <img :src="require('../../assets/img/wx_green.png')" />-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div slot="footer" class="dialog-footer text-center">
                <el-button class="width-100 footer-btn" type="primary" @click="handleSubmit">登录</el-button>
                <p class="text-center marginT-10"><a class="fc-blue" href="javascript:;" @click="forget">忘记密码？</a></p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        exData: {
            type: [String, Object],
            default: '',
        },
    },
    data() {
        return {
            dialog: !!this.value,
            showOtherLogin: true,
            innerdialog: false,
            checked: true,
            form: {
                phone: '',
                password: '',
            },
            rules: {
                phone: [
                    {
                        type: 'string',
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        type: 'string',
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    methods: {
        closeEvent() {
            sessionStorage.removeItem('open_id')
        },
        async handleSubmit() {
            this.$refs.form.validate(async valid => {
                sessionStorage.removeItem('sign')
                let uploadData = {
                    ...this.form,
                }
                if (sessionStorage.getItem('open_id')) {
                    uploadData.open_id = sessionStorage.getItem('open_id')
                }
                const { code, data, msg } = await this.$fetch('/tool/members/login', uploadData)
                // sessionStorage.removeItem('open_id');
                if (code === 0) {
                    this.$store.commit('login', {
                        payload: data,
                        callback: () => { // 登录处理后的回调
                            console.log("进入回调")
                            this.$store.dispatch('upDateUser')

                            this.$bus.emit('handleLogin')
                            this.$emit('success', this.exData)
                            this.dialog = false
                            // this.$router.go(0) // 会导致 dispatch('upDateUser') 执行失败
                        }
                    })
                    // 这个地方为啥延迟 400 毫秒可能是因为登录后的 token 还没有处理, 而 upDateUser 是需要 token 的, 所以延迟 400 毫秒等 token 处理函数处理完再执行 upDateUser
                    // 但是这样, 又出现了一个新的问题, 那就是 登录成功后, 会跳转路由且关闭弹框, 400毫秒后执行的函数因为路由跳转后不执行了, 所以 upDateUser 永远不会执行
                    // 解决办法是, upDateUser 在 dispathc('seLogin', data) 后调用
                    // setTimeout(() => {
                    //     this.$store.dispatch('upDateUser')
                    // }, 400)
                    // if (this.isToolMainSite()) {
                    //     setTimeout(() => {
                    //         this.$alert(`福利一：首次使用礼品商城即可获得7天会员 （联系微信客服领取）<br>
                    //                     福利二：一天内使用礼品商城20笔，即可领取月会员（联系微信客服领取）<br>
                    //                     福利三：一次充值满1000元送半年会员、满1500元送一年（接口用户不参与）<br>
                    //                     福利四：当天使用礼品商城，当天可以免费申请分站加盟（联系客服加盟）`, '春季福利', {
                    //             confirmButtonText: '前往商城',
                    //             dangerouslyUseHTMLString: true,
                    //             showClose: false,
                    //             callback: action => {
                    //                 if (action == 'confirm') {
                    //                     this.$router.push('/store/list')
                    //                 }
                    //             }
                    //         })
                    //     }, 2000);
                    // }

                    // ls.set('token', data.token)
                    // ls.set('tokenTime', new Date().getTime())
                    // this.$store.dispatch(`base/info/get`)
                } else {
                    if (msg == '账号或密码不正确') {
                        setTimeout(() => {
                            this.forget()
                        }, 1400)
                    }
                }
            })
        },
        async sendCode() {
            if (this.isSend) return
            //手机号正则验证
            var reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
            if (!this.form.phone) {
                this.$message.error('请输入手机号码')
                return
            }
            if (!reg.test(this.form.phone)) {
                //手机号不合法
                this.$message.error('您输入的手机号码不合法，请重新输入')
            }
            const { code } = await api.post('kmd/msg', {
                phone: this.form.phone,
            })
            if (code === 1001) {
                this.$message.success('验证码发送成功!')
                this.time = 60
                this.timer()
            }
        },
        timer() {
            if (this.time > 0) {
                this.time--
                this.btnContent = this.time + 's后重新获取'
                this.isSend = true
                window.timer = setTimeout(this.timer, 1000)
            } else if (this.time === 0) {
                this.btnContent = '获取验证码'
                if (window.timer) clearTimeout(window.timer)
                this.isSend = false
            }
            console.log(this.time)
        },
        forget() {
            this.$emit('forget')
        },
        weChatLoginClick() {
            this.$emit('wechatlogin')
        },
    },
    mounted() {
        console.log('进入了登录')
        console.log('this.dialog', this.dialog)
        if (this.getQueryVariable('', 'loginPhone')) {
            this.form.phone = this.getQueryVariable('', 'loginPhone')
        }

        if (sessionStorage.getItem('sign')) {
            this.showOtherLogin = false
        }
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        },
    },
}
</script>
<style lang="scss">
.login-dialog-content {
    .el-dialog__body {
        padding-bottom: 0 !important;
    }
}
.bind-phone-dialog {
    .bind-phone {
        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #666;
            a {
                color: #2b90ed;
            }
        }
        .rules-phone .el-form-item__content {
            margin-left: 10px !important;
            margin-top: -15px;
        }
        .send {
            .el-input-group__append {
                background-color: #aaa;
                border-color: #aaa;
                .el-button.is-disabled:hover {
                    border-color: transparent;
                    background-color: transparent;
                    color: inherit;
                }
            }
        }
        .el-input-group__append {
            cursor: pointer;
            background-color: #2b90ed;
            border-color: #2b90ed;
            color: #fff;
        }
    }
    div[role='alert'] {
        margin-top: -20px;
        margin-bottom: 20px;
        padding: 5px 10px;
    }
}
.footer-btn {
    margin-top: -30px;
}
.dividing-line {
    position: relative;
    width: 100%;
    height: 22px;
    text-align: center;
    i {
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: -100px;
        display: inline-block;
        width: 200px;
        height: 1px;
        background-color: #888;
    }
    span {
        position: absolute;
        left: 50%;
        margin-left: -48px;
        display: inline-block;
        padding: 0 6px;
        background-color: #fff;
    }
}
.img-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-item {
        width: 36px;
        height: 36px;
        margin: 0 8px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
