import Vue from 'vue'
import './plugins/element.js'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/index.scss'

import VueBus from './plugins/vue-bus'

import './plugins/rules'
import './plugins/enum'

import './api/index'
import './utils'

Vue.use(VueBus)
