import Vue from 'vue'
import { MessageBox } from 'element-ui'
import store from "./store"

const filters = {
    dateFormatUtils: (date, fmt = 'yyyy-MM-dd HH:mm:ss') => {
        if (!date) {
            return ''
        }
        let _date = new Date(date)
        let o = {
            'M+': _date.getMonth() + 1, //月份
            'd+': _date.getDate(), //日
            'h+': _date.getHours() % 12 == 0 ? 12 : _date.getHours() % 12, //小时
            'H+': _date.getHours(), //小时
            'm+': _date.getMinutes(), //分
            's+': _date.getSeconds(), //秒
            'q+': Math.floor((_date.getMonth() + 3) / 3), //季度
            S: _date.getMilliseconds() //毫秒
        }
        let week = {
            '0': '/u65e5',
            '1': '/u4e00',
            '2': '/u4e8c',
            '3': '/u4e09',
            '4': '/u56db',
            '5': '/u4e94',
            '6': '/u516d'
        }
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (_date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        if (/(E+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '/u661f/u671f' : '/u5468') : '') + week[_date.getDay() + ''])
        }
        for (let k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
            }
        }
        return fmt
    }
}

var utils = {
    dateFormatUtils: (date, fmt = 'yyyy-MM-dd HH:mm:ss') => {
        if (!date) {
            return ''
        }
        let _date = new Date(date)
        let o = {
            'M+': _date.getMonth() + 1, //月份
            'd+': _date.getDate(), //日
            'h+': _date.getHours() % 12 == 0 ? 12 : _date.getHours() % 12, //小时
            'H+': _date.getHours(), //小时
            'm+': _date.getMinutes(), //分
            's+': _date.getSeconds(), //秒
            'q+': Math.floor((_date.getMonth() + 3) / 3), //季度
            S: _date.getMilliseconds() //毫秒
        }
        let week = {
            '0': '/u65e5',
            '1': '/u4e00',
            '2': '/u4e8c',
            '3': '/u4e09',
            '4': '/u56db',
            '5': '/u4e94',
            '6': '/u516d'
        }
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (_date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        if (/(E+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '/u661f/u671f' : '/u5468') : '') + week[_date.getDay() + ''])
        }
        for (let k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
            }
        }
        return fmt
    },
    getQueryVariable: (url, variable) => {
        var query = url ? url.search.substring(1) : window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    },
    param2Obj: (url) => {
        const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
        if (!search) {
            return {}
        }
        const obj = {}
        const searchArr = search.split('&')
        searchArr.forEach(v => {
            const index = v.indexOf('=')
            if (index !== -1) {
                const name = v.substring(0, index)
                const val = v.substring(index + 1, v.length)
                obj[name] = val
            }
        })
        return obj
    },
    getkfWx: () => {
        const list = ['a2061880542', 'juzivtu', 'stkjnannan', 'a2061880542', 'vtgjaz', 'a2061880542']
        let wx = list[Math.round(Math.random() * (list.length - 1))]

        if (+store.state.configData.is_kefu_show) {
            return wx
        } else {
            return ""
        }

        // // 分站
        // if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_MODE === 'clone') {
        //     if (+store.state.configData.is_kefu_show) {
        //         return wx
        //     } else  {
        //         return ""
        //     }
        //
        // } else {
        //     // 主站
        //     // 随机取一个
        //     return wx
        // }
    },
    isToolMainSite: (igLoc) => {
        return true; // 永久返回 true, 因为这是主站UI的分站项目, 需要确保UI是主站的
        // TODO: 测试环境返回 false, 做测试用
        if (window.location.href.includes('http://localhost')) {
            return false;
        }
        if (location && location.host && location.host === 'test.vv-tool.com') {
            // 忽略测试环境
            return igLoc ? false : true
        }
        console.log("process.env.NODE_ENV", process.env.NODE_ENV);
        console.log("process.env.VUE_APP_MODE", process.env.VUE_APP_MODE);
        return process.env.NODE_ENV === 'production' && process.env.VUE_APP_MODE === 'clone' ? false : true
    },
    // 判断当前站点 url 是否是输入的url
    hasSiteUrl: (url) => {
        if (window.location.href.indexOf(url) > -1) {
            return true
        }

        return false;
    },
    messageAlert: (msg) => {
        MessageBox.alert(msg, '提示', {
            confirmButtonText: '确定',
            callback: action => {

            }
        });
    },

    // 信息脱贫
    textDesensitization: (text) => {
        // 暂时只是示例数据脱敏, 实际数据不脱敏
        return text;
        let res = "";

        if (!text) {
            return "";
        }

        const zhuan = (text) => {
            let res = "";

            if (text) {
                res = text[0] + "*";
            }

            return res;
        }

        if (text.includes("【")) {
            res = text.split("【").map(item => {
                let itemRes = "";
                if (item.includes("，")) {
                    itemRes = item.split("，").map(cItem => {
                        return zhuan(cItem)
                    }).join("，")
                } else {
                    itemRes = zhuan(item);
                }

                return itemRes;
            }).filter((item) => {
                return item;
            }).join("】【")

            res = `【${res}】`;
        } else if (text.includes(">>")) {
            res = text.split(">>").map(item => {
                let itemRes = "";
                if (item.includes("/")) {
                    itemRes = item.split("/").map(cItem => {
                        return zhuan(cItem)
                    }).join("/")
                } else {
                    itemRes = zhuan(item);
                }

                return itemRes;
            }).filter((item) => {
                return item;
            }).join(">>")
        } else if (text.includes("，")) {
            res = text.split("，").map(item => {
                return zhuan(item)
            }).filter((item) => {
                return item;
            }).join("，")
        } else {
            res = zhuan(text);
        }

        return res;
    },

    isValidIP(ip) {
        var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
        return reg.test(ip);
    },
    user_order_status: {
        p: '待发货', // USER_ORDER_STATUS_PAID
        s: '已发货', // USER_ORDER_STATUS_SHIPPED
        c: '取消订单', // USER_ORDER_STATUS_CANCEL
        f: '待支付', // USER_ORDER_STATUS_CANCEL
        u: '未同步', // USER_ORDER_SYNC_STATUS_UNSHIPPED
        // s: '已同步' // USER_ORDER_SYNC_STATUS_SHIPPED
    },
    order_source: {
        'taobao': '淘宝',
        'tmall': '天猫',
        'jd': '京东',
        'pdd': '拼多多',
        'dyxd': '抖音小店',
        'other': '其它'
    },
    recharge_type: {
        'c': '充值',
        'p': '支出',
        'r': '退款',
    },
    phone_fee_order_type: {
        1: '慢充',
        2: '快充',
        3: '拼多多'
    },
    phone_fee_order_status: {
        0: '待支付',
        1: '处理中',
        2: '全部退款',
        3: '部分退款',
        4: '取消',
        5: '已完成'
    }
}

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

Object.keys(utils).forEach(key => {
    Vue.prototype[key] = utils[key]
})

export default utils
