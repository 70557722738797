<template>
    <div>
        <el-dialog :visible.sync="dialog" width="350px" :close-on-click-modal="true" top="50px">
            <div class="text-center">
                <div v-if="!exData.url">二维码不存在或二维码已过期</div>
                <div class="showqrcode flex">
                    <div class="qrcode" ref="qrcode_record" id="qrcode_record"></div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
    props: {
        value: {
            type: Boolean,
            default: true,
        },
        exData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            dialog: !!this.value,
        }
    },
    methods: {
        // 生成二维码
        qrcode(data) {
            if (this.$refs.qrcode_record) {
                this.$refs.qrcode_record.innerHTML = ''
            }
            let qrcode = new QRCode(this.$refs.qrcode_record, {
                text: data,
                width: 220,
                height: 220,
                correctLevel: 3,
            })
            this.$refs.qrcode_record.removeAttribute('title')
            setTimeout(() => {
                this.$refs.qrcode_record.removeAttribute('title')
            }, 100);
        },
    },
    mounted () {
        this.$nextTick(() => {
            if (this.exData.url) {
                this.qrcode(this.exData.url);
            }
        })
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        },
    },
}
</script>

<style lang="scss" scoped>
.qr-tip-img {
    flex-basis: 42px;
}
.showqrcode {
    align-items: flex-end;
    justify-content: center;
}
.qrcode {
    margin: 10px 30px;
}
.fc-green {
    color: green;
}
</style>
