<template>
    <div class="top-nav">
        <navbar></navbar>
        <!-- <siderBar></siderBar> -->
    </div>
</template>

<script>
import { methods } from 'vue-awesome';
import navbar from './navbar';
import { mapState } from 'vuex'
// import siderBar from './siderBar';
export default {
    components: {
        navbar,
        // siderBar
    },
    computed:{
        ...mapState(['configData']),
    },
    mounted(){
        if (
            this.configData &&
            this.configData.proxy_info &&
            this.configData.proxy_info.site_title
        ) {
            document.title = this.configData.proxy_info.site_title
        }

        if (
            this.configData &&
            this.configData.proxy_info &&
            this.configData.proxy_info.site_ico
        ) {
            document.querySelector('link[rel="icon"]').href
                = "http://" + this.configData.static_url + "/" +  this.configData.proxy_info.site_ico
        }
    },
    watch: {
        configData(n, o) {
            if (n.proxy_info && n.proxy_info.site_title) {
                document.title = n.proxy_info.site_title
            }

            if (n.proxy_info && n.proxy_info.site_ico) {
                document.querySelector('link[rel="icon"]').href
                = "http://" + n.static_url + "/" +  n.proxy_info.site_ico
            }
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
