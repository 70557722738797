
import Vue from 'vue'
import { Message, MessageBox } from 'element-ui'

// 手机号验证
var mobile = (rule, value, callback) => {
    let regFormat = /^[1][3456789][0-9]{9}$/; //正确手机号
    if (!value) {
        return callback(new Error('不能为空'));
    }
    if (!(regFormat.test(value))) {
        callback(new Error('请输入正确手机号'));
    } else {
        callback();
    }
};
//数字验证
var number = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('不能为空'));
    }
    if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
    } else {
        if (value < 18) {
            callback(new Error('必须大于18岁'));
        } else if (value > 55) {
            callback(new Error('必须小于55岁'));
        } else {
            callback();
        }
    }
};
//邮箱
var email = (rule, value, callback) => {
    let mal = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!value) {
        return callback(new Error('不能为空'));
    }
    if (!(mal.test(value))) {
        callback(new Error('请输入正确邮箱'));
    } else {
        callback();
    }
};
//开始时间
var startTime = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('请选择开始日期'));
    } else {
        callback();
    }
};
//开始时间
var endTime = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('请选择结束日期'));
    } else {
        callback();
    }
};
//时间
var time = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('请选择日期'));
    } else {
        callback();
    }
};
//多选框
var checkbox = (rule, value, callback) => {
    if (value.length < 1) {
        return callback(new Error('请选择一个'));
    } else {
        callback();
    }
};
//单选框
var radio = (rule, value, callback) => {
    if (value.length < 1) {
        return callback(new Error('请选择一个'));
    } else {
        callback();
    }
};
//下拉框
var select = (rule, value, callback) => {
    if (value.length < 1) {
        return callback(new Error('请选择一个'));
    } else {
        callback();
    }
};

// 类似金钱,首位不为0,最多2位小数
function checkNumPot2(rule, value, callback) {
    const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
    if (!value) {
        return callback(new Error('请填写数字'))
    } else if (!reg.test(value)) {
        return callback(new Error('请填写数字,最多2位小数'))
    } else {
        callback()
    }
}

// 身份证
function checkIdNum(rule, value, callback) {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
    if (!value) {
        return callback(new Error('证件号码不能为空'))
    } else if (!reg.test(value)) {
        return callback(new Error('证件号码不正确'))
    } else {
        callback()
    }
}

// 整数
function checkInterNum(rule, value, callback) {
    const reg = /^[0-9]*[1-9][0-9]*$/
    if (!value) {
        return callback(new Error('请填写整数'))
    } else if (!reg.test(value)) {
        return callback(new Error('请输入整数'))
    } else {
        callback()
    }
}

var goods_info = (rule, value, callback) => {
	callback()
	return
    if (value && value.goods_id === -1) {
        return
    }
    if (!value || !value.goods_title) {
        return callback(new Error('请输入正确商品链接'))
    } else {
        callback()
    }
}
const fine_flow_tag = (rule, value, callback) => {
    callback()
    return
    if (value && value.length > 0) {
        callback()
    } else {
        return callback(new Error('人群标签至少选择一个'))
    }
}
const juUrl = (rule, value, callback) => {
    if (value.replace(/ /g, '').indexOf('https://detail.tmall.com/') !== 0) {
        Message.error('请输入正确的聚划算链接')
        callback(new Error('请输入正确的聚划算链接'))
    } else {
        callback()
    }
}
const confirmPasswordValidator = (rule, value, callback, password) => {
    if (password !== value) {
        callback(new Error('两次密码输入不一致'))
    } else {
        callback()
    }
}
const imgArrRequire = (rule, value, callback) => {
    if (value && value.length == 0) {
        callback(new Error('至少一张图片'))
    } else {
        callback()
    }
}

const formRules = {
    mobile: [{ required: true, pattern: /^1[23456789]\d{9}$/, message: '目前只支持中国大陆的手机号码', trigger: 'blur' }],
    password: [{ required: true, min: 6, max: 18, message: '长度必须大于6位长度在 6 到 18 个字符之间', trigger: 'blur' }],
    confirmPassword: (form) => {
        return [
            { required: true, min: 6, max: 18, message: '长度必须大于6位长度在 6 到 18 个字符之间', trigger: 'blur' },
            { required: true, validator: (rule, value, callback) => confirmPasswordValidator(rule, value, callback, form.password), trigger: 'blur' }
        ]
    },
    taokouling: [
        {required: true, min: 1, message: '请输入微淘链接/淘口令', trigger: 'blur'}
    ],
    ju_url: [
        {required: true, min: 1, message: '请输入聚划算链接', trigger: 'blur'},
        {required: true, validator: juUrl, trigger: 'blur'}
    ],
    imgArr: [
        {required: true, validator: imgArrRequire, trigger: 'blur'}
    ],
    verify_code: [{required: true, min: 6, max: 6, message: '验证码不符合要求', trigger: 'blur'}],
    required: [{required: true, message: '输入不能为空', trigger: 'blur'}],
    shopInfo: [{required: true, validator: goods_info, trigger: 'blur'}],
    fine_flow_tag: [{required: true, validator: fine_flow_tag, trigger: 'blur'}],
    numPot2: [{ required: true, validator: checkNumPot2, trigger: 'blur' }],
    InterNum: [{ required: true, validator: checkInterNum, trigger: 'blur' }],
    coins: [{ required: true, validator: checkInterNum, trigger: 'blur' }],
    // goodsTitle: [{ required: true, max: 32, message: '任务标题的最大长度为32个字符', trigger: 'blur' }],
    goodsTitle: [{ max: 32, message: '任务标题的最大长度为32个字符', trigger: 'blur' }],
}

Vue.prototype.formRules = formRules
