<template>
    <div>
        <el-dialog
            title="客服"
            :visible.sync="dialog"
            :close-on-click-modal="false"
            width="600px"
            top="50px"
        >
            <div class="flex text-center flex-between">
                <p v-if="configData.proxy_info && configData.proxy_info.site_qrcode" class="flex-1">
                    微信客服
                    <br />
                    <img
                        :src="configData.proxy_info.site_qrcode.indexOf('http') > -1 ? configData.proxy_info.site_qrcode : 'http://' + configData.static_url + '/' + configData.proxy_info.site_qrcode"
                        alt
                    />
                </p>
                <p v-else-if="configData.is_proxy_site == 0" class="flex-1">
                    微信客服：
                    <br />
                    <!--<img v-show="singleOrDouble === 0" :src="require('../../assets/img/service_wechat_2.jpg')" alt />-->
                    <!--<img v-show="singleOrDouble === 1" :src="require('../../assets/img/service_wechat_3.jpg')" alt />-->
                    <img :src="getWxKeFU" alt />
                </p>
                <p v-if="configData.is_proxy_site == 0" class="flex-1">
                    <span v-show="singleOrDouble === 0">QQ客服：1956694751</span>
                    <br />
                    <span v-show="singleOrDouble === 1">QQ客服：3382108485</span>
                    <br />
                    <span>
                        <a v-show="singleOrDouble === 0" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=1956694751&site=qq&menu=yes" title="客服" >
                            <el-link class="marginT-20" type="primary">跳转至QQ</el-link>
                        </a>
                        <a v-show="singleOrDouble === 1" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=3382108485&site=qq&menu=yes" title="客服" >
                            <el-link class="marginT-20" type="primary">跳转至QQ</el-link>
                        </a>
                    </span>
                </p>
                <div v-else-if="configData.proxy_info && configData.proxy_info.site_qq && configData.proxy_info.site_qq.length > 0" class="flex-1">
                    <div v-for="(item, index) in configData.proxy_info.site_qq" :key="index" class="marginT-10">
                        <span>QQ客服：{{item.value}}</span>
                        <span>
                            <a target="_blank" class="fc-theme marginL-20" :href="`http://wpa.qq.com/msgrd?v=3&uin=${item.value}&site=qq&menu=yes`" title="客服" >
                                跳转至QQ
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import appConfig from '../../mixin/appConfig'
import dayjs from 'dayjs'
import img1 from "../../assets/img/service_wechat_2.jpg"
import img2 from "../../assets/img/service_wechat_3.jpg"
import img3 from "../../assets/img/service_wechat_4.png"
import img4 from "../../assets/img/service_wechat_5.png"

export default {
    mixins: [appConfig],
    props: {
        value: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dialog: !!this.value
        }
    },
    computed: {
        // 返回 1 或 0 表示当前日期是单或双
        singleOrDouble() {
            return dayjs().date() % 2
        },
        getWxKeFU: () => {
            // 随机取一个
            const list = [img1, img2, img3, img4]
            return list[Math.round(Math.random() * (list.length - 1))]
        },
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        }
    }
}
</script>

<style lang="scss" scoped>
img {
    width: 200px;
}
</style>
