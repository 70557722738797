import Vue from 'vue'
import Vuex from 'vuex'
import { fetch } from './api/index'
import Cookies from 'js-cookie'
Vue.use(Vuex)
import { basicMenu, headerMenu } from "@/index/layout/config"


export default new Vuex.Store({
    state: {
        phone: localStorage.phone || '',
        token: localStorage.token || '',
        loginModal: false,
        userData: localStorage.userData ? JSON.parse(localStorage.userData) : {},
        configData: {},
        siderBarCate: 1,
        info: {},

        messagePage: {
            page: 1,
            pageSize: 10,
        },
        messageLoading: false,
        messageList: [],
        messageUnreadCount: 0,
        messageTotal: 0,

        platformPage: {
            page: 1,
            pageSize: 10,
        },
        platformLoading: false,
        platformList: [],
        platformUnreadCount: 0,
        platformTotal: 0,

        socket: null, // 存放 web socket 实例的容器

        syncOrderCorn: {
            id: null, // 同步任务 ID
            syncing: false, // 是否同步中
        },

        navShowList: [],
        navShowListHasErr: false,

        myPackages: [], // 我的套餐
        basicMenu: basicMenu, // 默认总的菜单数据
        headerMenu: headerMenu, // 主站头部菜单
    },
    mutations: {
        login(state, payloadObj) {
            console.log({payloadObj})
            let payload = null;
            let callback = null;
            if (payloadObj.payload) { // 兼容之前的写法
                payload = payloadObj.payload;
                callback = payloadObj.callback;
            } else {
                payload = payloadObj
            }

            console.log({payload})

            localStorage.token = payload.access_token
            localStorage.phone = payload.phone
            state.phone = payload.phone
            state.token = payload.access_token

            if (location && location.host && location.hostname == 'localhost') {
                Cookies.set('_vt', payload.access_token)
            }else if (Vue.prototype.isToolMainSite('token')) {
                Cookies.set('_vt', payload.access_token, {path: '/', domain: '.vv-tool.com'})
            } else {
                let proxyRootDomain = location.hostname.split('.').slice(-2).join('.')
                Cookies.set('_vt', payload.access_token, {path: '/', domain: `.${proxyRootDomain}`})
            }

            console.log({callback})
            if (callback) {
                callback(); // 调用登录后的回调处理函数
            }

            payload.invite_code = btoa(payload.invite_code)
        },
        logout(state) {
            state.phone = ''
            state.token = ''
            localStorage.token = ''
            localStorage.phone = ''
            if (location && location.host && location.hostname == 'localhost') {
                Cookies.remove('_vt')
            } else if (Vue.prototype.isToolMainSite()) {
                Cookies.remove('_vt', {path: '/', domain: '.vv-tool.com'})
            } else {
                let proxyRootDomain = location.hostname.split('.').slice(-2).join('.')
                Cookies.remove('_vt', {path: '/', domain: `.${proxyRootDomain}`})
            }
            sessionStorage.removeItem('sign');
            sessionStorage.removeItem('open_id');
            localStorage.removeItem('userData');
            // sessionStorage.clear();
            // localStorage.clear();
            state.userData = {};
        },
        setUserData(state, payload) {
            payload.invite_code = btoa(payload.invite_code)
            state.userData = payload
            state.phone = payload.phone
            localStorage.phone = payload.phone
            // console.log("setUserData", payload)
            localStorage.userData = JSON.stringify(payload)
        },
        setSiderBarCate(state, payload) {
            state.siderBarCate = payload
        },
        openLoginModal(state) {
            state.loginModal = !state.loginModal
        },
        getConfig(state, payload) {
            state.configData = payload
        },

        setNavShowList(state, payload) {
            state.navShowList = payload;
        },
        setNavShowListHasErr(state, payload) {
            state.navShowListHasErr = payload;
        },

        setMessageLoading(state, payload) {
            state.messageLoading = payload;
        },
        setMessageUnreadCount(state, payload) {
            state.messageUnreadCount = payload;
        },
        setMessageList(state, payload) {
            state.messageList = payload;
        },
        setMessagePage(state, payload) {
            state.messagePage = payload;
        },
        setMessageTotal(state, payload){
            state.messageTotal = payload;
        },

        setPlatformLoading(state, payload) {
            state.platformLoading = payload;
        },
        setPlatformUnreadCount(state, payload) {
            state.platformUnreadCount = payload;
        },
        setPlatformList(state, payload) {
            state.platformList = payload;
        },
        setPlatformPage(state, payload) {
            state.platformPage = payload;
        },
        setPlatformTotal(state, payload) {
            state.platformTotal = payload;
        },

        setMyPackages(state, payload) {
            state.myPackages = payload;
        },


        newWebSocket(state, payload) {
            if (!state.socket) {
                let socketUrl = `ws://socket.vv-tool.com:8090/vtoolUser?user_id=${state.userData.uid}`;

                if (process.env.NODE_ENV == 'production') {
                    if(process.env.VUE_APP_APIPATH == 'test') {
                        socketUrl = `ws://test.socket.vv-tool.com:8080/vtoolUser?user_id=${state.userData.uid}`;
                    }
                } else {
                    socketUrl = `ws://test.socket.vv-tool.com:8080/vtoolUser?user_id=${state.userData.uid}`;
                }

                state.socket = new WebSocket(socketUrl);
                // state.socket = new WebSocket(`ws://${location.host}/socket/vtoolUser?user_id=${state.userData.uid}`);
                // state.socket = new WebSocket(`ws://test.socket.vv-tool.com:8080/vtoolUser?user_id=${state.userData.uid}`);
            }
        },

        setMenu(state, payload) {
            state.basicMenu = payload.basicMenu
            state.headerMenu = payload.headerMenu
        }
    },
    actions: {
        setSiderBarCate(context, args) {
            context.commit('setSiderBarCate', args)
        },
        setLogin(context, args) {
            context.commit('login', args)
        },
        setLogout(context) {
            context.commit('logout')
        },
        setUserData(context, args) {
            context.commit('setUserData', args)
        },
        openLoginModal(context, args) {
            context.commit('openLoginModal')
        },
        async upDateUser(context) {
            const data = await fetch('/tool/members/info')
            if (data.code == 0) {
                context.commit('setUserData', data.data)
            }
        },
        async getConfig(context) {
            // localStorage.initData = 1
            const data = await fetch('/tool/apps/init', {})

            if (data.code == 0) {
                if (data.data.is_proxy_site == 1 && data.data.proxy_info) {
                    localStorage.logo = data.data.proxy_info.site_logo
                    localStorage.theme = data.data.proxy_info.site_theme
                    localStorage.site_name = data.data.proxy_info.site_name
                }
            }
            if (data && data.data) {
                context.commit('getConfig', data.data)

                if (data.data.baidu_count_code) {
                    var _hmt = _hmt || []
                    window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
                    (function () {
                        var hm = document.createElement("script");
                        hm.src = "https://hm.baidu.com/hm.js?" + data.data.baidu_count_code;
                        var s = document.getElementsByTagName("script")[0];
                        s.parentNode.insertBefore(hm, s);
                    })();
                }
            } else {
                context.commit('getConfig', {})
            }
            // localStorage.removeItem('initData')
        },


        // 获取消息通知
        async getDataMessage({ state, commit }) {
            commit('setMessageLoading', true);
            try {
                const res = await fetch('/tool/usersmessages/list-users-message', {
                    type: 1, // 1-用户端 2-分站
                    msg_type: 1, // 1-通知类型 2-公告类型
                    page: state.messagePage.page,
                    pageSize: state.messagePage.pageSize,
                })
                if (res.code == 0) {
                    let info = res.data;

                    commit('setMessageUnreadCount', info.unreadCount);
                    commit('setMessageTotal', info.total);
                    commit('setMessageList', info.data);
                }
            } finally {
                commit('setMessageLoading', false);
            }
        },

        // 获取平台公告
        async getDataPlatform({ state, commit }) {
            commit('setPlatformLoading', true);
            try {
                const res = await fetch('/tool/usersmessages/list-users-message', {
                    type: 1, // 1-用户端 2-分站
                    msg_type: 2, // 1-通知类型 2-公告类型
                    page: state.platformPage.page,
                    pageSize: state.platformPage.pageSize,
                })
                if (res.code == 0) {
                    let info = res.data;

                    commit('setPlatformUnreadCount', info.unreadCount);
                    commit('setPlatformTotal', info.total);
                    commit('setPlatformList', info.data);
                }
            } finally {
                commit('setPlatformLoading', false);
            }
        },

        // 获取菜单 showCode
        async getNavList({state, commit}) {
            try {
                let res = await fetch('/tool/apps/get-nav-list', {})

                if (res.code === 0) {
                    commit('setNavShowList', res.data);
                    commit('setNavShowListHasErr', false);
                }
            } catch (e) {
                console.log({e})
                // 报错了, 直接返回全部数据
                commit('setNavShowListHasErr', true);
            } finally {

            }
        },

        // 获取我的套餐
        async getMyPackages({ state, commit }) {
            try {
                const res = await fetch('/api/proxy/web/function/package/myself', {}, "get")
                if (+res.code === 0) {
                    let info = res.data;
                    commit("setMyPackages", info)
                }
            } finally {

            }
        },

    }
})
