<template>
    <div>
        <el-dialog title="订单确认" :visible.sync="dialog" width="640px" top="150px">
            <div>
                <!-- 选中 checked 的订单 -->
                <div class="item" v-if="exData.checkedResult && exData.checkedResult.length">
                    <p class="item-label">购买功能清单:</p>
                    <div>
                        <el-tag v-for="(item, index) in exData.checkedResult" :key="index" size="small" type="danger" style="margin: 0 10px 10px 0;">
                            <span>{{item.package_name}}</span>
                            <span style="margin: 0 8px;">{{item.tool_count_desc}}</span>
                            <span v-if="item.checkedType === 'month'">1月</span>
                            <span v-if="item.checkedType === 'season'">1季</span>
                            <span v-if="item.checkedType === 'year'">1年</span>
                        </el-tag>
                    </div>
                </div>
                <!-- 套餐订单 -->
                <div v-if="exData.packageCode">
                    <div class="item">
                        <p class="item-label">购买套餐:</p>
                        <p style="font-weight: 600;">{{exData.packagesData[exData.packageCode].package_name}}</p>
                    </div>
                    <div class="item">
                        <p class="item-label">购买功能清单:</p>
                        <div>
                            <template v-for="(item, index) in exData.packagesData[exData.packageCode].package_function_codes">
                                <el-tag v-if="item.tool_code !== 'commontool'" :key="index" size="small" type="danger" style="margin: 0 10px 10px 0;">
                                    <span>{{item.tool_name}}</span>
                                    <span style="margin: 0 8px;">{{item.tool_count_desc}}</span>
                                    <span v-if="exData.packageCode === 'testPackage'">7天</span>
                                    <span v-if="exData.packageCode === 'basePackage'">30天</span>
                                    <span v-if="exData.packageCode === 'highPackage'">90天</span>
                                    <span v-if="exData.packageCode === 'zhuanyePackage'">365天</span>
                                </el-tag>
                            </template>
                        </div>
                    </div>
                    <div class="item">
                        <p class="item-label">赠送功能清单:</p>
                        <div>
                            <el-tag v-for="(item, index) in exData.isWuxianzhiArr" :key="index" size="small" type="danger" style="margin: 0 10px 10px 0;">
                                <span>{{item.package_name}}</span>
                                <span style="margin: 0 8px;">{{item.wuxianzhiDesc}}</span>
                                <span v-if="exData.packageCode === 'testPackage'">7天</span>
                                <span v-if="exData.packageCode === 'basePackage'">30天</span>
                                <span v-if="exData.packageCode === 'highPackage'">90天</span>
                                <span v-if="exData.packageCode === 'zhuanyePackage'">365天</span>
                            </el-tag>
                        </div>
                    </div>
                </div>

                <div class="item">
                    <p class="item-label">当前账号:</p>
                    <p>{{$store.state.userData.phone}}</p>
                </div>

                <!-- 非导出淘宝订单显示 -->
                <div v-if="!exData.exportBuy">
                    <div class="item">
                        <p class="item-label">折扣价:</p>
                        <p>
                            <span style="font-weight: 600; color: #f00;">￥{{exData.totalMoney}}</span>
                            <span style="color: #888;">/{{exData.totalMoney * 100}}点券</span>
                        </p>
                    </div>
                    <!--<div class="item">-->
                    <!--    <p class="item-label">折扣:</p>-->
                    <!--    <p>-->
                    <!--        <span style="font-weight: 600; color: #f00;">￥0</span>-->
                    <!--        <span style="color: #888;">/0点券</span>-->
                    <!--    </p>-->
                    <!--</div>-->
                    <div class="item">
                        <p class="item-label">合计:</p>
                        <p>
                            <span style="font-weight: 600; color: #f00;">￥{{exData.totalMoney}}</span>
                            <span style="color: #888;">/{{exData.totalMoney * 100}}点券</span>
                        </p>
                    </div>
                </div>

                <!-- 导出淘宝订单 -->
                <div v-if="exData.exportBuy">
                    <div class="item">
                        <p class="item-label">购买打单软件:</p>
                        <div>
                            <el-input-number v-model="exportCount" size="small" :min="1" :step="100"></el-input-number>
                        </div>
                    </div>
                    <div class="item">
                        <p class="item-label">单价:</p>
                        <p><span>￥{{exportCountPrice}}/单</span></p>
                    </div>
                    <div class="item">
                        <p class="item-label">有效期:</p>
                        <p><span style="color: #f00;">长期</span></p>
                    </div>
                    <div class="item">
                        <p class="item-label">折扣价:</p>
                        <p>
                            <span style="font-weight: 600; color: #f00;">￥{{calcMul(exportCount, exportCountPrice)}}</span>
                            <span style="color: #888;">/{{calcMul(calcMul(exportCount, exportCountPrice), 100)}}点券</span>
                        </p>
                    </div>
                    <!--<div class="item">-->
                    <!--    <p class="item-label">折扣:</p>-->
                    <!--    <p>-->
                    <!--        <span style="font-weight: 600; color: #f00;">￥0</span>-->
                    <!--        <span style="color: #888;">/0点券</span>-->
                    <!--    </p>-->
                    <!--</div>-->
                    <div class="item">
                        <p class="item-label">合计:</p>
                        <p>
                            <span style="font-weight: 600; color: #f00;">￥{{calcMul(exportCount, exportCountPrice)}}</span>
                            <span style="color: #888;">/{{calcMul(calcMul(exportCount, exportCountPrice), 100)}}点券</span>
                        </p>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer text-center">
                <el-button @click="dialog = false">取 消</el-button>
                <el-button type="danger" :loading="loading" @click="submitClick">立即支付</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {calcMul, calcDiv} from '@/index/plugins/calc'

export default {
    props: {
        value: {
            type: Boolean,
            default: true
        },
        exData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            dialog: !!this.value,
            exportCount: 10000,
            exportCountPrice: 0,
        }
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        }
    },
    created() {
        this.priceLimit()
    },
    methods: {
        calcMul,
        async priceLimit() {
            const res = await this.$fetch('/api/proxy/web/tool/detail', {
                name: "exchange",
            })

            if (this.$store.state.userData.is_vip) {
                this.exportCountPrice = calcDiv(res.data.vip_point, 100)
            } else {
                this.exportCountPrice = calcDiv(res.data.point, 100)
            }
        },

        submitClick() {
            if (!localStorage.phone) {
                window.openLogin();
                // this.$store.dispatch('openLoginModal')
                return
            }

            // 选中 checked 的订单
            if (this.exData.checkedResult && this.exData.checkedResult.length) {
                this.checkedBuy()
                return
            }

            // 套餐订单
            if (this.exData.packageCode) {
                this.packageBuy()
                return
            }

            // 导出淘宝订单
            if (this.exData.exportBuy) {
                this.exportBuy()
                return
            }
        },

        async checkedBuy() {
            let package_items = [];

            let checkedType = {
                "month": 1,
                "season": 2,
                "year": 3,
            }

            this.exData.checkedResult.forEach(item => {
                package_items.push({
                    package_code: item.package_code,
                    package_time_type: checkedType[item.checkedType]
                })
            })

            try {
                this.loading = true;
                let res = await this.$fetch('/api/proxy/web/function/package/once/buy', {
                    package_items,
                }, 'post')

                if (res.code === 0) {
                    this.$message.success("购买成功")
                    this.$emit("success")
                    this.dialog = false;
                }
            } finally {
                this.loading = false;
            }
        },

        async packageBuy() {
            try {
                this.loading = true;
                let res = await this.$fetch('/api/proxy/web/function/package/buy', {
                    package_code: this.exData.packageCode,
                }, 'post')

                if (res.code === 0) {
                    this.$message.success("购买成功")
                    this.$emit("success")
                    this.dialog = false;
                }
            } finally {
                this.loading = false;
            }
        },

        async exportBuy() {
            try {
                this.loading = true;
                let res = await this.$fetch('/api/proxy/web/function/package/taobao/buy', {
                    down_num: this.exportCount,
                }, 'post')

                if (res.code === 0) {
                    this.$message.success("购买成功")
                    this.$emit("success")
                    this.dialog = false;
                }
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.item {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .item-label {
        width: 160px;
        text-align: right;
        font-weight: 600;
        margin-right: 6px;
        flex-shrink: 0;
    }
}
</style>
