<template>
    <div class="no-bind-user" v-loading="loading">
        <el-dialog title="微信登录" :visible.sync="dialog" center width="400px" :close-on-click-modal="false" top="50px" custom-class="login-dialog-content" @close="dialogClose">
            <div class="bind-phone-dialog">
                <div>微信未绑定账号</div>
                <div style="margin-top: 16px;">
                    <el-button type="primary" style="width: 240px;" @click="goLogin">已有账号, 登录绑定</el-button>
                </div>
                <div style="margin-top: 16px;">
                    <el-button type="primary" style="width: 240px;" @click="goRegister">快速注册</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            dialog: !!this.value,
            loading: true,
        }
    },
    mounted() {},
    methods: {
        goLogin() {
            this.$emit('showLogin')
        },
        goRegister() {
            this.$emit('showRegister')
        },
        dialogClose() {
            sessionStorage.removeItem('sign');
        }
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        },
    },
}
</script>
<style lang="scss">
.no-bind-user {
    .login-dialog-content {
        .el-dialog__body {
            padding-bottom: 0 !important;
        }
    }
    .bind-phone-dialog {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 26px;
    }
    .footer-btn {
        margin-top: -30px;
    }
    .dividing-line {
        position: relative;
        width: 100%;
        height: 22px;
        text-align: center;
        i {
            position: absolute;
            top: 10px;
            left: 50%;
            margin-left: -100px;
            display: inline-block;
            width: 200px;
            height: 1px;
            background-color: #888;
        }
        span {
            position: absolute;
            left: 50%;
            margin-left: -48px;
            display: inline-block;
            padding: 0 6px;
            background-color: #fff;
        }
    }
    .img-content {
        display: flex;
        justify-content: center;
        align-items: center;
        .img-item {
            width: 36px;
            height: 36px;
            margin: 0 8px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
