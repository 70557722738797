<template>
    <div>
        <el-dialog title="提示" :visible.sync="dialog" width="350px" :close-on-click-modal="true" top="50px">
            <div class="text-center">
                <p style="font-size: 18px; color: #333; position: relative; top: -20px;">{{ exData.title ? exData.title : '推送成功' }}</p>
                <p style="font-size: 14px; color: #888; position: relative; top: -10px;" v-if="loading">打标成功, 下单二维码生成中, 请稍后</p>
                <p style="font-size: 14px; color: #888; position: relative; top: -10px;" v-else-if="qrErr">打标成功, 但下单二维码因淘宝限制生成失败, 请重新生成二维码或放弃</p>
                <p style="font-size: 14px; color: #888; position: relative; top: -10px;" v-else>打标成功, 下单二维码生成成功</p>
                <div class="showqrcode flex">
                    <div v-loading="loading" style="text-align: center; position: relative;">
                        <div class="qrcode" ref="qrcode_record" id="qrcode_record"></div>
                        <div v-show="qrErr" class="qr-err">
                            <p style="padding-top: 80px; color: #fff; font-weight: 600;">二维码失效</p>
                            <el-button style="margin-top: 20px;" type="primary" @click="getData" size="mini">重新生成</el-button>
                        </div>
                    </div>
                </div>
                <p v-show="qrErr" style="color: #f00;">备注: 二维码生成失败不影响打标效果</p>
                <el-button style="margin-top: 20px;" type="primary" @click="dialog = false;">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'

export default {
    props: {
        value: {
            type: Boolean,
            default: true
        },
        exData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            dialog: !!this.value,
            loading: false,
            qrErr: false // 二维码错误
        }
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (typeof(this.exData.info) === "string") {
                this.qrcode(this.exData.info)
            } else {
                this.getData()
            }
        })
    },
    methods: {
        async getData() {
            this.qrErr = false
            try {
                this.loading = true
                const res = await this.$fetch('/tool/accounts/kashouping-code', {
                    ...this.exData.info,
                    keyword: this.exData.info.keyword || this.exData.info.tag
                }, 'post')

                if (res.code === 0) {
                    this.qrcode(res.data)
                } else {
                    this.qrcode('二维码生成失效')
                    this.qrErr = true
                }
            } finally {
                this.loading = false
            }
        },

        // 生成二维码
        qrcode(data) {
            if (this.$refs.qrcode_record) {
                this.$refs.qrcode_record.innerHTML = ''
            }
            let qrcode = new QRCode(this.$refs.qrcode_record, {
                text: data,
                width: 220,
                height: 220,
                correctLevel: 3
            })
            this.$refs.qrcode_record.removeAttribute('title')
            setTimeout(() => {
                this.$refs.qrcode_record.removeAttribute('title')
            }, 100)
        }
    }
}
</script>

<style lang="scss" scoped>
.qr-tip-img {
    flex-basis: 42px;
}

.showqrcode {
    align-items: flex-end;
    justify-content: center;
}

.qrcode {
    margin: 10px 30px;
}

.fc-green {
    color: green;
}

.qr-err {
    position: absolute;
    width: 220px;
    height: 220px;
    top: 10px;
    left: 30px;
    background: rgba(0, 0, 0, .8);
}
</style>
