<template>
    <div class="wx-login" v-loading="loading">
<!--        <remote-script src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js" @load="weLoginLoad"></remote-script>-->
<!--        <el-dialog :visible.sync="dialog" center width="400px" :close-on-click-modal="false" top="50px" custom-class="login-dialog-content">-->
<!--            <div class="bind-phone-dialog">-->
<!--                <span id="login_container"></span>-->
<!--            </div>-->
<!--        </el-dialog>-->
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            dialog: !!this.value,
            loading: true,
            sign: '',
        }
    },
    mounted() {},
    methods: {
        // 微信登录的 js 加载完成
        weLoginLoad() {
            let getRandom = () => {
                var num = ''
                for (var i = 0; i < 10; i++) {
                    num += Math.floor(Math.random() * 10)
                }
                return num;
            }
            this.sign = +(new Date) + getRandom();

            let apiHost = 'http://www.vv-tool.com';
            // let apiHost = 'http://localhost:8080';
            let urlHost = '';

            if (process.env.NODE_ENV == 'production') {
                console.log('%cWeChatLogin.vue line:45 process.env', 'color: #007acc;', process.env);
                if(process.env.VUE_APP_APIPATH == 'test') {
                    apiHost = 'http://test.vv-tool.com';
                }

                urlHost = apiHost + '/homepage';
            } else {
                apiHost = 'http://test.vv-tool.com';
                urlHost = 'http://localhost:8080' + '/homepage';
            }

            if (sessionStorage.getItem('directBinding') == 'true') {
                let obj = new WxLogin({
                    id: 'login_container',
                    appid: 'wx1d683f40f9248a5a',
                    scope: 'snsapi_login',
                    redirect_uri: encodeURIComponent(`${apiHost}/tool/members/wechat-call-back?type=${2}&uid=${this.$store.state.userData.uid}&sign=${this.sign}&url_host=${urlHost}`),
                    state: 1,
                    style: 'black',
                    href: '',
                })
            } else {
                let obj = new WxLogin({
                    id: 'login_container',
                    appid: 'wx1d683f40f9248a5a',
                    scope: 'snsapi_login',
                    redirect_uri: encodeURIComponent(`${apiHost}/tool/members/wechat-call-back?type=${1}&sign=${this.sign}&url_host=${urlHost}`),
                    state: 1,
                    style: 'black',
                    href: '',
                })
            }

            this.loading = false
        },

        requestLoginStatus() {},
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        },
    },
}
</script>
<style lang="scss">
.wx-login {
    .login-dialog-content {
        .el-dialog__body {
            padding-bottom: 0 !important;
        }
    }
    .bind-phone-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-btn {
        margin-top: -30px;
    }
    .dividing-line {
        position: relative;
        width: 100%;
        height: 22px;
        text-align: center;
        i {
            position: absolute;
            top: 10px;
            left: 50%;
            margin-left: -100px;
            display: inline-block;
            width: 200px;
            height: 1px;
            background-color: #888;
        }
        span {
            position: absolute;
            left: 50%;
            margin-left: -48px;
            display: inline-block;
            padding: 0 6px;
            background-color: #fff;
        }
    }
    .img-content {
        display: flex;
        justify-content: center;
        align-items: center;
        .img-item {
            width: 36px;
            height: 36px;
            margin: 0 8px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
