//导入自定义组件
import TopTip from "@/index/components/TopPrice";

//写进数组，方面接下来的遍历
const componentArr = [TopTip]

const cgi = {
    //install为Vue中的默认写法，我们在main.js中使用cgi,项目会自动执行install方法
    //此方法还会接收一个参数 Vue
    install(Vue) {
        //遍历自定义组件 全局使用
        componentArr.forEach(item => {
            Vue.component(item.name, item)
        })
    }
}

export default cgi
