<template>
    <div class="register-dialog">
        <el-dialog
            :visible.sync="dialog"
            center
            width="800px"
            @close="closeEvent"
            :close-on-click-modal="false"
            top="50px"
        >
            <div style="display: flex; align-items: flex-start;">
                <div class="tips-info" style="width: 375px; height: 455px; background-color: #00a2fe; color: #fff;">
                    <p class="title" style="padding: 36px; font-size: 30px; color: #fff; text-align: center;">扫码领专属福利</p>
                    <div class="tips-item-wrap">
                        <div class="tips-item">
                            <div class="tips-icon">
                                <img :src="require('@/index/assets/img/免费.png')" />
                            </div>
                            <p>首次充值可以领取免费会员</p>
                        </div>
                        <div class="tips-item">
                            <div class="tips-icon">
                                <img :src="require('@/index/assets/img/客服.png')" />
                            </div>
                            <p>专属的客服指导功能使用</p>
                        </div>
                        <div class="tips-item">
                            <div class="tips-icon">
                                <img :src="require('@/index/assets/img/文件夹.png')" />
                            </div>
                            <p>店铺问题随时咨询</p>
                        </div>
                    </div>
                    <div class="qr-wrap" v-if="$store.state.configData">
                        <img :src="getWxKeFU()" alt />
                    </div>
                </div>
                <div class="bind-phone-dialog" style="width: 400px; padding: 25px 25px 30px;">
                    <div class="title" style="font-size: 16px; font-weight: 600; text-align: center; padding-bottom: 20px;">
                        <p>免费注册</p>
                    </div>
                    <el-form ref="form" :model="form" class="bind-phone">
                        <el-form-item prop="phone" :rules="[{ validator: validatePhone, trigger: ['blur', 'change'] }]">
                            <!--<el-input v-model.trim="form.phone" :maxlength="11" placeholder="请输入手机号码" @blur="orignCheckPhone"></el-input>-->
                            <el-input v-model.trim="form.phone" placeholder="请输入手机号码" @blur="orignCheckPhone"></el-input>
                        </el-form-item>
                        <el-form-item class="img-code">
                            <el-input placeholder="请输入结果" v-model="form.imgCode">
                            </el-input>
                            <img class="captcha-img" @click="reloadImg"
                                :src="codeImg">
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="请输入验证码" v-model="form.sms_code">
                                <el-button slot="append" class="sendMsg"
                                    @click="sendCode" :disabled="isSend || isCheckDisabled">
                                    <span>{{btnContent}}</span>
                                </el-button>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="请输入您的密码" v-model="form.password">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input placeholder="请输入确认密码"
                                @keyup.enter.native="handleSubmit"
                                v-model="form.ConfirmPassword">
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div class="footer dialog-footer text-center">
                        <el-button class="width-100 footer-btn" type="primary" @click="handleSubmit">注册</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import apiConfig from '../../api/api-config'
import { mapState } from 'vuex'
import img1 from '@/index/assets/img/service_wechat_2.jpg'
import img2 from '@/index/assets/img/service_wechat_3.jpg'
import img3 from '@/index/assets/img/service_wechat_4.png'
import img4 from '@/index/assets/img/service_wechat_5.png'

export default {
    props: {
        value: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            dialog: !!this.value,
            loading: false,
            time: 0,
            codeImg: '/tool/apps/captcha',
            form: {
                register_type: 'phone',
                phone: '',
                sms_code: '',
                password: '',
                ConfirmPassword: '',
                imgCode: ''
            },
            btnContent: '获取验证码',
            isCheckDisabled: true,
            isSend: false
        }
    },
    computed: {
        checkPhone() {
            const preg = /^[1][23456789][0-9]{9}$/
            return preg.test(this.form.phone)
        },
        checkImgCode() {
            const length = this.form.imgCode.length
            return length > 0
        },
        checkCode() {
            return !!this.form.sms_code
        },
        checkPassword() {
            const length = this.form.password.length
            return length >= 6 && length <= 18
        },
        checkRepPassword() {
            return this.form.password === this.form.ConfirmPassword
        },
        ...mapState(['configData']),
    },
    mounted() {
        if (this.$route.query.aff) {
            localStorage.aff = this.$route.query.aff
        }
        this.reloadImg()
    },
    methods: {
        getWxKeFU() {
            let configData = this.$store.state.configData;
            if (+configData.is_proxy_site === 0) {
                // 主站随机取一个
                const list = [img1, img2, img3, img4]
                return list[Math.round(Math.random() * (list.length - 1))]
            } else if (configData.proxy_info && configData.proxy_info.site_qrcode) {
                // configData.proxy_info.site_qrcode.indexOf('http') > -1 ? configData.proxy_info.site_qrcode : 'http://' + configData.static_url + '/' + configData.proxy_info.site_qrcode
                if (configData.proxy_info.site_qrcode.indexOf('http') > -1) {
                    return configData.proxy_info.site_qrcode
                } else {
                    return 'http://' + configData.static_url + '/' + configData.proxy_info.site_qrcode
                }
            }
        },

        closeEvent() {
            sessionStorage.removeItem('open_id')
        },

        validatePhone(rules, value, callback) {
            //手机号正则验证
            var reg = 11 && /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
            if (!reg.test(value)) {
                callback(new Error('手机号格式不对'))
            }else{
                this.isCheckDisabled = false
                callback()
            }
        },
        async orignCheckPhone() {
            console.log(this.form.phone);
            if (this.form.phone && this.form.phone.length == 11) {
                const { data, code } = await this.$fetch('/tool/apps/phone-check',{ mobile: this.form.phone, type: 'register' },'get')
                if (code == 0) {
                    this.isCheckDisabled = false
                }
            }
        },
        async handleSendCode() {
            if (this.checkPhone && this.checkImgCode && this.time <= 0) {
                this.loading = true
                const {
                    data: { code }
                } = await api.post('sendResetMsgWithCaptcha', {
                    phone: this.form.phone,
                    code: this.form.imgCode
                })
                this.loading = false
                if (code === 1001) {
                    this.$message.success('验证码发送成功!')
                    this.time = 60
                    this.clearTime()
                }
            } else {
                this.$message.error('请填写手机号码和图形码!')
            }
        },
        async handleSubmit() {
            if (!this.checkPhone) {
                this.$message.error('请填写手机号码!')
                return
            }
            if (!this.checkImgCode) {
                this.$message.error('请填写图形码!')
                return
            }
            if (!this.checkCode) {
                this.$message.error('请填写验证码!')
                return
            }
            if (!this.checkPassword) {
                this.$message.error('密码不能为空, 长度在6-18位之间!')
                return
            }
            if (!this.checkRepPassword) {
                this.$message.error('密码和确认密码不一致!')
                return
            }
            this.loading = true
            if (this.$route.query.aff || localStorage.aff) {
                this.form.invite_code = localStorage.aff
            }
            if (localStorage.spm) {
                this.form.promote_code = localStorage.spm
            }
            sessionStorage.removeItem('sign');
            let uploadData = {
                ...this.form,
                ConfirmPassword: undefined,
                imgCode: undefined,
            }
            if (sessionStorage.getItem("open_id")) {
                uploadData.open_id = sessionStorage.getItem("open_id");
            }
            const { code, data } = await this.$fetch(
                '/tool/members/register',
                uploadData,
                'post'
            )
            this.loading = false
            if (code === 0) {
                // this.$message.success('注册成功')
                this.$store.dispatch('setLogin', data)
                setTimeout(() => {
                    this.$store.dispatch('upDateUser')
                }, 400)
                this.$emit('success', sessionStorage.getItem("open_id"));
                // sessionStorage.removeItem('open_id');
                this.dialog = false;
            }
            // if (code === 1001) {
            //     this.reset()
            //     this.$message({
            //         type: 'success',
            //         message: '密码重置成功!'
            //     })
            //     setTimeout(() => {
            //         this.$router.push('/login')
            //     }, 1000)
            // }
        },
        reloadImg() {
            this.codeImg += '?' + Math.random()
        },
        async sendCode() {
            if (this.isSend) return
            //手机号正则验证
            var reg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
            if (!this.form.phone) {
                this.$message.error('请输入手机号码')
                return
            }
            if (!reg.test(this.form.phone)) {
                //手机号不合法
                this.$message.error('您输入的手机号码不合法，请重新输入')
                return
            }
            this.isSend = true
            this.btnContent = '正在获取...'
            const data = await this.$fetch('/tool/apps/sms', {
                mobile: this.form.phone,
                verify_code: this.form.imgCode,
                type: 'register'
            })
            if (data.code === 0) {
                this.$message.success('验证码发送成功!')
                this.time = 60
                this.timer()
            } else {
                this.isSend = false
                this.btnContent = '获取验证码'
            }
        },
        timer() {
            if (this.time > 0) {
                this.time--
                this.btnContent = this.time + 's后重新获取'
                // this.isSend = true
                window.timer = setTimeout(this.timer, 1000)
            } else if (this.time === 0) {
                this.btnContent = '获取验证码'
                if (window.timer) clearTimeout(window.timer)
                this.isSend = false
            }
        }
    },
    watch: {
        dialog(val) {
            if (!val) this.$emit('input', val)
        },
        'form.phone'(nval, oval) {
            if (nval.length < 11) {
                this.isCheckDisabled = true
            }
        }
    }
}
</script>
<style lang="scss">
.register-dialog {
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__body {
        padding: 0 !important;
    }
    .tips-item-wrap {
        padding: 0 36px;
        .tips-item {
            display: flex;
            align-items: center;
            margin-top: 20px;
            background-color: #fff;
            border-radius: 10px;
            .tips-icon {
                width: 20px;
                height: 20px;
                padding: 10px;
                border-radius: 10px;
                img {
                    width: 100%;
                    height: 100%;
                }
                background-color: #6a80fe;
            }
            p {
                color: #6a80fe;
                font-size: 20px;
                line-height: 40px;
                padding-left: 10px;
            }
        }
    }

    .qr-wrap {
        padding-top: 30px;
        text-align: center;
        img {
            width: 100px;
            height: 100px;
        }
    }
}
.bind-phone-dialog {
    .bind-phone {
        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #666;
            a {
                color: #2b90ed;
            }
        }
        .rules-phone .el-form-item__content {
            margin-left: 10px !important;
            margin-top: -15px;
        }
        .send {
            .el-input-group__append {
                background-color: #aaa;
                border-color: #aaa;
                .el-button.is-disabled:hover {
                    border-color: transparent;
                    background-color: transparent;
                    color: inherit;
                }
            }
        }
        .el-button.is-disabled {
            background: #2b90ed;
            border-color: #2b90ed;
        }
        .el-input-group__append {
            cursor: pointer;
            background-color: #2b90ed;
            border-color: #2b90ed;
            color: #fff;
        }
    }
    div[role='alert'] {
        margin-top: -20px;
        margin-bottom: 20px;
        padding: 5px 10px;
    }
    .captcha-img {
        position: absolute;
        height: 38px;
        right: 1px;
        top: 3px;
    }
}
.footer-btn {
    margin-top: -30px;
}
</style>
