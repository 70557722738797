import Cookies from "js-cookie";

// 主站菜单
// showTemplate, 1 只在主站顶部菜单有效, 2 只在新分站顶部菜单有效, 3 只在旧分站顶部菜单有效
// showTemplate 不存在都展示, 存在则展示标记的站点
export const headerMenu = [
    {
        name: "首页",
        url(userInfo) {
            return "/homepage"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: null,
        showCode: "header_sy",
    },
    {
        name: "电商工具",
        url(userInfo) {
            return "/elTool"
        },
        target: "_blank",
        customerLink: {
            active($route, siderBarCate) {
                return siderBarCate <= 2 && !$route.path.includes('/homepage');
            }
        },
        noCustomerLinkActive: {
            active($route) {
                return $route.path.includes('/weightpush') || $route.path.includes('/head_screen/recommend') || $route.path.includes('/home') || $route.path.includes('/checkAccount')
            }
        },
        hasTips: null,
        showCode: "header_dsgj",
    },
    {
        name: "大数据验号",
        url(userInfo) {
            return "/checkAccount"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: "全网独一",
        showCode: "header_wwzyj",
        showTemplate: 2,
    },
    {
        name: "标签黑科技",
        url(userInfo) {
            return "/weightpush"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: null,
        showCode: "header_bqhkj",
        showTemplate: 2,
    },
    {
        name: "卡首屏",
        url(userInfo) {
            return "/head_screen/recommend"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: null,
        showCode: "header_ksp",
        showTemplate: 2,
    },
    {
        name: "购买礼品",
        url(userInfo) {
            return "/store/list"
        },
        target: "_blank",
        customerLink: {
            active($route, siderBarCate) {
                return $route.path.includes('/store/');
            }
        },
        noCustomerLinkActive: null,
        hasTips: null,
        showCode: "header_lpfh",
    },
    {
        name: "竞品监控",
        url(userInfo) {
            return "/monitor/competitive"
        },
        target: "_blank",
        customerLink: {
            active($route, siderBarCate) {
                return $route.path.includes('/monitor/');
            }
        },
        noCustomerLinkActive: null,
        hasTips: "SKU监控",
        showCode: "header_jpjk",
    },
    // {
    //     name: "流量中心",
    //     url(userInfo) {
    //         return "/flow/taobao"
    //     },
    //     target: "_blank",
    //     customerLink: {
    //         active($route, siderBarCate) {
    //             return $route.path.includes('/flow/');
    //         }
    //     },
    //     noCustomerLinkActive: null,
    //     hasTips: null,
    //     showCode: "header_llzx",
    // },
    {
        name: "礼品发货",
        url(userInfo) {
            return "https://www.1688yuncang.com/"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: null,
        showCode: "wts_yuncang",
        onlySite: 'wangtoushi.cn',
    },
    {
        name: "API开放平台",
        url(userInfo) {
            return "http://doc.vv-tool.com"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: null,
        showCode: "header_apikfpt",
        // showTemplate: 1,
    },
    {
        name: "电商知识库",
        url(userInfo) {
            return `http://ask.vv-tool.com/login${userInfo.phone ? `?username=${userInfo.phone}&key=${userInfo.password}` : ''}`
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: null,
        showCode: "header_dszsk",
        showTemplate: 1,
    },
    {
        name: "打单软件",
        url(userInfo) {
            return "javascript:;"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: null,
        clickHandle(isToolMainSite, configData) {
            let token = '';

            if (isToolMainSite()) {
                let CookieToken = Cookies.get('_vt')
                let localToken = localStorage.token

                if (CookieToken && CookieToken !== localToken) {
                    localStorage.token = CookieToken
                }
                if (CookieToken) {
                    token = CookieToken
                } else {
                    token = localStorage.token
                }
            } else {
                token = localStorage.token
            }

            if (configData.wmt_host) {
                window.open(`http://${configData.wmt_host}/wmt-vt/login-with?token=${token}`)
            } else {
                window.open(`/wmt-vt/login-with?token=${token}`)
            }
        },
        showCode: "header_ddrj",
        // showTemplate: 1,
    },
    {
        name: "充值会员",
        url(userInfo) {
            return "/vip"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: null,
        showCode: "header_czhy",
    },
    {
        name: "购买套餐",
        url(userInfo) {
            return "/buy-package"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: "2折",
        showCode: "header_gmtc",
    },
    {
        name: "代理加盟",
        url(userInfo) {
            return "/agent-join"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: "OEM",
        showCode: "header_dljm",
    },
    {
        name: "插件下载",
        url(userInfo) {
            return "/chrome111-extensions-download"
        },
        target: "_blank",
        customerLink: null,
        noCustomerLinkActive: null,
        hasTips: "免费",
        showCode: "header_cjxz", // 这里为了方便测试, 把
    },
];

export const basicMenu = [
    {
        "name": "验号中心",
        "url": "/home",
        "showCode": "title-yhzx",
        "children": [
            {
                "name": "旺旺标签权重评估",
                "url": "/ww-label-weight",
                "tag": [
                    "一键透视买家权重评分",
                ],
                "desc": "一键透视买家的权重评分",
                "imgCode": "wwqzpg",
                "showCode": "ww-label-weight",
            },
            {
                "name": "旺旺照妖镜",
                "url": "/home",
                "tag": [
                    "实时标签",
                    "安全评估",
                    "打标类型",
                ],
                "desc": "一键解析指定旺旺号信息",
                "imgCode": "wwzyj",
                "showCode": "home",
            },
            {
                "name": "淘宝号标签查询",
                "url": "/tagSearch",
                "tag": [
                    "客观反应官方用户画像",
                ],
                "desc": "客观反应官方用户画像",
                "imgCode": "tbhbqcx",
                "showCode": "tagSearch",
            },
            {
                "name": "防P图验号",
                "url": "/validPicture",
                "tag": [
                    "查地址",
                    "查性别",
                    "查足迹",
                    "查评价",
                ],
                "desc": "防止职业打假师, 职业差评师",
                "imgCode": "fptyh",
                "showCode": "validPicture",
            },
            {
                "name": "搜索下拉推荐词",
                "url": "/lexRecommend",
                "tag": [
                    "实时查询",
                    "关联词",
                    "下拉列表",
                ],
                "desc": "查询官方推荐的关联词",
                "imgCode": "ssxltjc",
                "showCode": "lexRecommend",
            },
            {
                "name": "旺旺搜索词推荐",
                "url": "/lexTaobaoTag",
                "tag": [
                    "淘宝推荐",
                    "关键标签",
                    "实时查询",
                ],
                "desc": "查询指定淘宝号推荐搜索词",
                "imgCode": "ssxltjc",
                "showCode": "lexTaobaoTag",
            },
            {
                "name": "大数据验号",
                "url": "/checkAccount",
                "tag": [
                    "旺旺信息",
                    "标签查询",
                ],
                "desc": "旺旺基本信息、标签查询",
                "imgCode": "dsjyh",
                "showCode": "checkAccount",
            },
            {
                "name": "授权验号",
                "url": "/authCheck",
                "tag": [
                    "旺旺信息",
                    "标签查询",
                    "评论透析",
                    "订单透析",
                ],
                "desc": "旺旺信息、标签、评论、订单查询",
                "imgCode": "sqyh",
                "showCode": "authCheck",
            },
            {
                "name": "抖音验号",
                "url": "/dy-check",
                "tag": [
                    "查标签",
                    "数据分析",
                    "查账号信息",
                ],
                "desc": "抖音账号数据分析",
                "imgCode": "dyyh",
                "showCode": "dy-check",
            },
        ],
    },
    {
        "name": "标签推送",
        "url": "/weightpush",
        "showCode": "title-bqts",
        "children": [
            {
                "name": "手淘搜索推送",
                "url": "/weightpush",
                "tag": [
                    "推送商品到搜索首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品到搜索首屏，提高搜索权重",
                "imgCode": "stssts",
                "showCode": "weightpush",
            },
            {
                "name": "猜你喜欢推送",
                "url": "/push/also-like",
                "tag": [
                    "精准推送商品到搜索首屏",
                    "提高搜索权重",
                ],
                "desc": "精准推送商品到搜索首屏，提高搜索权重",
                "imgCode": "cnxhts",
                "showCode": "push_also-like",
            },
            {
                "name": "手淘微详情推送",
                "url": "/push/mobile-small",
                "tag": [
                    "精准推送商品到搜索首屏",
                    "提高搜索权重",
                ],
                "desc": "精准推送商品到搜索首屏，提高搜索权重",
                "imgCode": "stwxqts",
                "showCode": "push_mobile-like",
            },
            {
                "name": "手淘直播推送",
                "url": "/push/also-like-home",
                "tag": [
                    "精准推送商品到搜索首屏",
                    "提高搜索权重",
                ],
                "desc": "精准推送商品到搜索首屏，提高搜索权重",
                "imgCode": "stzbts",
                "showCode": "push_also-like-home",
            },
            {
                "name": "购物车推荐",
                "url": "/push/also-like-cart",
                "tag": [
                    "将商品推荐至购物车下方",
                    "提高搜索权重",
                ],
                "desc": "推送商品到搜索首屏，提高搜索权重",
                "imgCode": "gwctj",
                "showCode": "push_also-like-cart",
            },
            {
                "name": "淘内免费推送",
                "url": "/push/my-taobao",
                "tag": [
                    "精准推送商品到搜索首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品到搜索首屏，提高搜索权重",
                "imgCode": "tnmfts",
                "showCode": "push_my-taobao",
            },
            {
                "name": "旺旺搜索打标",
                "url": "/marking",
                "tag": [
                    "魔搜",
                    "指定打标",
                    "引流利器",
                ],
                "desc": "魔搜、指定打标、引流利器",
                "imgCode": "wwssdb",
                "showCode": "marking",
            },
            {
                "name": "拼多多打标",
                "url": "/pddScreen",
                "tag": [
                    "多号推送",
                    "商品标签打标",
                ],
                "desc": "商品标签打标，实现拼多多首页推荐",
                "imgCode": "pdddb",
                "showCode": "pddScreen",
            },
        ],
    },
    {
        "name": "卡首屏",
        "url": "/checkNum/firstPause",
        "showCode": "title-ksp",
        "children": [
            {
                "name": "无痕做单",
                "url": "/traceless",
                "tag": [
                    "提供转化率",
                    "提高搜索词权重",
                ],
                "desc": "直接下单不需浏览宝贝",
                "imgCode": "whbd",
                "showCode": "traceless",
            },
            {
                "name": "关键词卡首屏",
                "url": "/checkNum/firstPause",
                "tag": [
                    "解决新品没流量",
                    "补手淘关键词流量",
                ],
                "desc": "补淘宝搜索关键词流量",
                "imgCode": "gjcksp",
                "showCode": "checkNum_firstPause",
            },
            {
                "name": "找相似宝贝",
                "url": "/checkNum/similarImport",
                "tag": [
                    "多样化首选",
                    "多流量来源",
                ],
                "desc": "多流量来源入口，多样化流量首选",
                "imgCode": "zxsrk",
                "showCode": "checkNum_similarImport",
            },
            {
                "name": "微详情",
                "url": "/head_screen/micro-details",
                "tag": [
                    "卡微详情",
                    "猜你喜欢入池",
                ],
                "desc": "卡微详情，猜你喜欢入池",
                "imgCode": "stwxqts",
                "showCode": "head_screen_micro-details",
            },
            {
                "name": "问大家",
                "url": "/head_screen/ask-everyone",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品到搜索首屏，提高搜索权重",
                "imgCode": "wdj",
                "showCode": "head_screen_ask-everyone",
            },
            {
                "name": "亲宝贝",
                "url": "/tbmobile/probaby",
                "tag": [
                    "多流量来源",
                    "多样化首选",
                ],
                "desc": "多流量来源，多样化流量",
                "imgCode": "qbb",
                "showCode": "tbmobile_probaby",
            },
            {
                "name": "购后猜你喜欢",
                "url": "/head_screen/buy-ask-everyone",
                "tag": [
                    "猜你喜欢打标",
                    "流量来源我的淘宝",
                ],
                "desc": "猜你喜欢打标，流量来源",
                "imgCode": "cnxhts",
                "showCode": "head_screen_buy-ask-everyone",
            },
            {
                "name": "首单优惠",
                "url": "/head_screen/first-order-discount",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品在首屏，提高搜索权重",
                "imgCode": "sdyh",
                "showCode": "head_screen_first-order-discount",
            },
            {
                "name": "芭芭农场",
                "url": "/head_screen/farm",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品在卡首屏，提高搜索权重",
                "imgCode": "bbnc",
                "showCode": "head_screen_farm",
            },
            {
                "name": "手淘旺信",
                "url": "/head_screen/wangwang",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品在卡首屏，提高搜索权重",
                "imgCode": "stwx",
                "showCode": "head_screen_wangwang",
            },
            {
                "name": "手淘其它商品详情页",
                "url": "/head_screen/other-shop",
                "tag": [
                    "竞品打标",
                    "卡竞品流量",
                ],
                "desc": "竞品打标，卡竞品流量",
                "imgCode": "stqtspxqy",
                "showCode": "head_screen_other-shop",
            },
            {
                "name": "淘内免费其它(通用版)",
                "url": "/head_screen/other-fee",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品在首屏，提高搜索权重",
                "imgCode": "tnmfqttyb",
                "showCode": "head_screen_other-fee",
            },
            {
                "name": "小黑盒",
                "url": "/head_screen/black-box",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品到搜索首屏，提高搜索权重",
                "imgCode": "xhh",
                "showCode": "head_screen_black-box",
            },
            {
                "name": "新品推荐",
                "url": "/head_screen/new-products",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "精准推送商品到搜索首屏，提高搜索权重",
                "imgCode": "xptj",
                "showCode": "head_screen_new-products",
            },
            {
                "name": "【高权重】搜索卡首屏",
                "url": "/head_screen/firstPause",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品在首屏，提高搜索权重",
                "imgCode": "gqzssksp",
                "showCode": "head_screen_firstPause",
            },
            {
                "name": "买家秀",
                "url": "/head_screen/buyers-show",
                "tag": [
                    "配合做单增加权重",
                ],
                "desc": "配合做单增加权重",
                "imgCode": "mjx",
                "showCode": "head_screen_buyers-show",
            },
            {
                "name": "天天特卖卡首屏",
                "url": "/head_screen/daily-deals",
                "tag": [
                    "推荐猜你喜欢",
                    "天天特卖流量来源",
                ],
                "desc": "推荐猜你喜欢，天天特卖流量来源",
                "imgCode": "tttmksp",
                "showCode": "head_screen_daily-deals",
            },
            {
                "name": "聚划算品牌特卖",
                "url": "/head_screen/poly-cost-effective",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品到搜索首屏，提高搜索权重",
                "imgCode": "jhspptm",
                "showCode": "head_screen_poly-cost-effective",
            },
            {
                "name": "支付页-猜你喜欢-流量来源手淘推荐",
                "url": "/head_screen/buy-page",
                "tag": [
                    "推送商品在首屏",
                    "提高搜索权重",
                ],
                "desc": "推送商品在首屏，提高搜索权重",
                "imgCode": "zfycnxhlllysttj",
                "showCode": "head_screen_buy-page",
            },
            {
                "name": "抖音卡首屏",
                "url": "/dy-first-page",
                "tag": [
                    "快速卡榜单入口",
                    "提高渠道入口权重",
                ],
                "desc": "快速卡榜单入口、提高渠道入口权重",
                "imgCode": "dyksp",
                "showCode": "dy-first-page",
            },
            {
                "name": "1688卡首屏",
                "url": "/first-flow-albb",
                "tag": [
                    "快速卡榜单入口",
                    "提高渠道入口权重",
                ],
                "desc": "快速卡榜单入口、提高渠道入口权重",
                "imgCode": "love",
                "showCode": "head_screen_recommend",
            },
            {
                "name": "抖音搜索卡首屏",
                "url": "/first-flow-dy/first-flow",
                "tag": [
                    "提高商品权重",
                    "流量来源-[搜索]",
                ],
                "desc": "提高商品权重，流量来源-【搜索】",
                "imgCode": "dytool",
                "showCode": "first_flow_dy_first_flow",
            },
            {
                "name": "抖音猜你喜欢",
                "url": "/first-flow-dy/guess",
                "tag": [
                    "流量来源-[商城推荐流量]",
                ],
                "desc": "流量来源-【商城推荐流量】",
                "imgCode": "dytool",
                "showCode": "first_flow_dy_guess",
            },
            {
                "name": "大家都说好",
                "url": "/first-flow-pdd/said-ok",
                "tag": [
                    "商品卡在首屏",
                    "极大的提升权重",
                ],
                "desc": "商品卡在首屏，极大的提升权重",
                "imgCode": "pddtool",
                "showCode": "first_flow_pdd_said_ok",
            },
            {
                "name": "拼多多搜索卡首屏",
                "url": "/first-flow-pdd/first-flow",
                "tag": [
                    "提升关键词搜索权重",
                ],
                "desc": "提升关键词搜索权重",
                "imgCode": "pddtool",
                "showCode": "first_flow_pdd_first_flow",
            },
            {
                "name": "卡首屏系列",
                "url": "/head_screen/recommend",
                "tag": [
                    "解决无流量问题",
                ],
                "desc": "多个卡首屏系列工具",
                "imgCode": "love",
                "showCode": "head_screen_recommend",
            },
            // 20240313
            {
                "name": "天天低价",
                "url": "/head_screen/everyday-low-price",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_everyday-low-price",
            },
            {
                "name": "购后推荐",
                "url": "/head_screen/post-purchase-recommandations",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_post-purchase-recommandations-low-price",
            },
            {
                "name": "淘宝好价",
                "url": "/head_screen/tb-good-price",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_tb-good-price",
            },
            {
                "name": "淘内待分类-通用",
                "url": "/head_screen/tnei-unclassified",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_tnei-unclassified",
            },
            {
                "name": "淘内待分类-猜你喜欢",
                "url": "/head_screen/tnei-guess-like",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_tnei-guess-like",
            },
            {
                "name": "百亿补贴",
                "url": "/head_screen/billions-subsidies",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_billions-subsidies",
            },
            {
                "name": "爱车生活",
                "url": "/head_screen/car-life",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_car-life",
            },
            {
                "name": "惠生活",
                "url": "/head_screen/favorable-life",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_favorable-life",
            },
            {
                "name": "生活电器",
                "url": "/head_screen/domestic-electric-appliance",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_domestic-electric-appliance",
            },
            {
                "name": "潮电数码",
                "url": "/head_screen/trend-electric-digital",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_trend-electric-digital",
            },
            {
                "name": "踏秋季",
                "url": "/head_screen/deep-in-autumn",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_deep-in-autumn",
            },
            {
                "name": "潮玩节",
                "url": "/head_screen/tend-fun-festival",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_tend-fun-festival",
            },
            {
                "name": "趣玩季",
                "url": "/head_screen/fun-season",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_fun-season",
            },
            {
                "name": "多巴胺",
                "url": "/head_screen/dopamine",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_dopamine",
            },
            {
                "name": "爱宠日",
                "url": "/head_screen/pet-day",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_pet-day",
            },
            {
                "name": "冰雪节",
                "url": "/head_screen/ice-festival",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_ice-festival",
            },
            {
                "name": "情人节",
                "url": "/head_screen/valentine-day",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_valentine-day",
            },
            {
                "name": "热卖鞋靴",
                "url": "/head_screen/hot-sale-shoes-boots",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_hot-sale-shoes-boots",
            },
            {
                "name": "服饰直播",
                "url": "/head_screen/live-dress",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_live-dress",
            },
            {
                "name": "春季新品",
                "url": "/head_screen/spring-new",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_spring-new",
            },
            {
                "name": "全球新趋势",
                "url": "/head_screen/global-trend",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_global-trend",
            },
            {
                "name": "全球新发现",
                "url": "/head_screen/global-discover",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_global-discover",
            },
            {
                "name": "国际自营",
                "url": "/head_screen/international-self-support",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_international-self-support",
            },
            {
                "name": "家居电器",
                "url": "/head_screen/home-appliance",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_home-appliance",
            },
            {
                "name": "家装好物",
                "url": "/head_screen/home-improvement-goodies",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_home-improvement-goodies",
            },
            {
                "name": "家装好价",
                "url": "/head_screen/home-improvement-nice-price",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_home-improvement-nice-price",
            },
            {
                "name": "宅家必备",
                "url": "/head_screen/home-must-have",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_home-must-have",
            },
            {
                "name": "家享生活",
                "url": "/head_screen/home-enjoy-life",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_home-enjoy-life",
            },
            {
                "name": "服饰时尚",
                "url": "/head_screen/fashion",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_fashion",
            },
            {
                "name": "精品家具",
                "url": "/head_screen/boutique-furniture",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_boutique-furniture",
            },
            {
                "name": "品质家电",
                "url": "/head_screen/quality-applicance",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_quality-applicance",
            },
            {
                "name": "运动新潮",
                "url": "/head_screen/sport-trend",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_sport-trend",
            },
            {
                "name": "运动好物",
                "url": "/head_screen/sport-goodies",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_sport-goodies",
            },
            {
                "name": "美食嗨吃",
                "url": "/head_screen/enjoy-nice-food",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_enjoy-nice-food",
            },
            {
                "name": "美妆洗护",
                "url": "/head_screen/beauty-toiletry",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_beauty-toiletry",
            },
            {
                "name": "母婴亲子",
                "url": "/head_screen/mother-baby",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_mother-baby",
            },
            {
                "name": "文教香氛",
                "url": "/head_screen/culture",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_culture",
            },
            {
                "name": "企业采购",
                "url": "/head_screen/corporate-procurement",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_corporate-procurement",
            },
            {
                "name": "宠企文教",
                "url": "/head_screen/corporate-culture",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_corporate-culture",
            },
            {
                "name": "趋势发布",
                "url": "/head_screen/publish-trend",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_publish-trend",
            },
            {
                "name": "超级单品",
                "url": "/head_screen/supper-item",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_supper-item",
            },
            {
                "name": "珠宝饰品",
                "url": "/head_screen/jewelry",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_jewelry",
            },
            {
                "name": "品牌盛典",
                "url": "/head_screen/brand-ceremony",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_brand-ceremony",
            },
            {
                "name": "流行女装",
                "url": "/head_screen/woman-fashion",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_woman-fashion",
            },
            {
                "name": "精致养宠",
                "url": "/head_screen/subtlety-pet",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_subtlety-pet",
            },
            {
                "name": "手表眼镜",
                "url": "/head_screen/watch-eyeglasses",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_watch-eyeglasses",
            },
            {
                "name": "健康生活",
                "url": "/head_screen/health-life",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_health-life",
            },
            {
                "name": "厨房家居",
                "url": "/head_screen/kitchenette",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_kitchenette",
            },
            {
                "name": "潮玩早享",
                "url": "/head_screen/pre-enjoy-trend",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_pre-enjoy-trend",
            },
            {
                "name": "床品家饰",
                "url": "/head_screen/bedding-home-decor",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_bedding-home-decor",
            },
            {
                "name": "春季上新",
                "url": "/head_screen/spring-newest",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_spring-newest",
            },
            {
                "name": "天猫U先",
                "url": "/head_screen/tmall-u-first",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_tmall-u-first",
            },
            {
                "name": "天猫超市",
                "url": "/head_screen/tmall-market",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_tmall-market",
            },
            {
                "name": "天猫买药",
                "url": "/head_screen/tmall-medicine",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_tmall-medicine",
            },
            {
                "name": "大牌数码",
                "url": "/head_screen/brand-digital",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_brand-digital",
            },
            {
                "name": "大牌珠宝",
                "url": "/head_screen/brand-jewelry",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_brand-jewelry",
            },
            {
                "name": "大牌母婴",
                "url": "/head_screen/brand-mother-baby",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_brand-mother-baby",
            },
            {
                "name": "大牌家清",
                "url": "/head_screen/brand-home-cleaner",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_brand-home-cleaner",
            },
            {
                "name": "大牌个护",
                "url": "/head_screen/brand-personal-care",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_brand-personal-care",
            },
            {
                "name": "大牌美妆",
                "url": "/head_screen/brand-beauty",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_brand-beauty",
            },
            {
                "name": "大牌男装",
                "url": "/head_screen/brand-man-clothes",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_brand-man-clothes",
            },
            {
                "name": "大牌内衣",
                "url": "/head_screen/brand-undergarments",
                "tag": [],
                "desc": "",
                "imgCode": "love",
                "showCode": "head_screen_brand-undergarments",
            },
            // 20240313 end
        ],
    },
    {
        "name": "手淘卡屏",
        "url": "/tbmobile/nicegoods",
        "showCode": "title-stll",
        "children": [
            {
                "name": "手淘推荐",
                "url": "/head_screen/recommend",
                "tag": [
                    "到手淘推荐的商品列表页",
                    "提升商品路径访问权重",
                ],
                "desc": "到手淘推荐的商品列表页，提升商品路径访问权重",
                "imgCode": "sttj",
                "showCode": "head_screen_recommend",
            },
            {
                "name": "有好货",
                "url": "/tbmobile/nicegoods",
                "tag": [
                    "手淘首页",
                    "流量来源",
                    "多流量来源",
                ],
                "desc": "快速得到手淘首页的流量来源",
                "imgCode": "hottj",
                "showCode": "tbmobile_nicegoods",
            },
            {
                "name": "猜你喜欢",
                "url": "/tbmobile/guess",
                "tag": [
                    "个性化推荐",
                    "快速流量来源",
                ],
                "desc": "个性化推荐快速流量来源",
                "imgCode": "cnxh",
                "showCode": "tbmobile_guess",
            },
            {
                "name": "智钻",
                "url": "/tbmobile/hbooth",
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "desc": "多流量来源入口，多样化流量",
                "imgCode": "hottj",
                "showCode": "tbmobile_hbooth",
            },
            {
                "name": "全球购",
                "url": "/tbmobile/globalshop",
                "tag": [
                    "全球购板块",
                    "类目限制",
                ],
                "desc": "多流量来源入口，多样化流量",
                "imgCode": "hottj",
                "showCode": "tbmobile_globalshop",
            },
            {
                "name": "每日好店",
                "url": "/tbmobile/dayshops",
                "tag": [
                    "首页位置",
                    "流量大",
                    "没有类目限制",
                ],
                "desc": "多流量来源入口，多样化流量",
                "imgCode": "hottj",
                "showCode": "tbmobile_dayshops",
            },
            {
                "name": "淘抢购",
                "url": "/tbmobile/robshop",
                "tag": [
                    "快速获取手淘首页来源",
                    "提高商品权重",
                ],
                "desc": "多流量来源入口，多样化流量首选",
                "imgCode": "tqg",
                "showCode": "tbmobile_robshop",
            },
            {
                "name": "淘金币",
                "url": "/head_screen/coins",
                "tag": [
                    "流量来源淘金币",
                ],
                "desc": "流量来源淘金币",
                "imgCode": "hottj",
                "showCode": "head_screen_coins",
            },
            {
                "name": "生活研究所",
                "url": "/head_screen/life",
                "tag": [
                    "解决无流量问题",
                ],
                "desc": "针对手淘人群流量入口精准补流量",
                "imgCode": "hottj",
                "showCode": "head_screen_life",
            },
            {
                "name": "iFashion",
                "url": "/tbmobile/iFashion",
                "tag": [
                    "潮流店铺",
                    "流量大",
                    "提升商品权重",
                ],
                "desc": "iFashion是潮流店铺或商品的代表",
                "imgCode": "hottj",
                "showCode": "tbmobile_iFashion",
            },
            // 20240307
            {
                "name": "U先使用",
                "url": "/tbmobile/brand-undergarments",
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "desc": "多流量来源入口，多样化流量",
                "imgCode": "hottj",
                "showCode": "tbmobile_brand-undergarments",
            },
            {
                "name": "手淘直播",
                "url": "/tbmobile/tm-live",
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "desc": "多流量来源入口，多样化流量",
                "imgCode": "hottj",
                "showCode": "tbmobile_tm-live",
            },
            {
                "name": "淘内待分类-精选发现",
                "url": "/tbmobile/tnei-discover",
                "tag": [
                    "淘内待分类",
                ],
                "desc": "生参后台显示访客来源和成交来源都是：淘内待分类",
                "imgCode": "hottj",
                "showCode": "tbmobile_tnei-discover",
            },
            {
                "name": '淘内待分类-为你推荐',
                "url": '/tbmobile/tnei-recommandations',
                "desc": '生参后台显示访客来源和成交来源都是：淘内待分类',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tnei-recommandations",
            },
            {
                "name": '手淘推荐云主题',
                "url": '/tbmobile/tmr-topic-cloud',
                "desc": '生参后台显示访客来源和成交来源都是：手淘推荐-推荐云主题',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tmr-topic-cloud"
            },
            {
                "name": '手淘必买清单',
                "url": '/tbmobile/tm-must-list',
                "desc": '生参后台显示访客来源和成交来源都是：手淘必买清单 - 手淘客户端APP必买清单',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tm-must-list"
            },
            {
                "name": '详情页头图推荐',
                "url": '/tbmobile/details-main-img',
                "desc": '访客通过手淘其他店铺的商品详情页引导进入您的商品详情页，如访客通过商品头图横向滑动至最右侧查看更多商品，会将您的商品通过系统推荐算法展示在更多相似宝贝的集合页面上。因为商品是系统推荐的，如果您店铺里的优秀商品很多，要补的商品是新品的话有可能无法卡到！生参后台会显示来源：手淘其他店铺详情 - 商品详情页头图推荐',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_details-main-img"
            },
            // {
            //     "name": '短视频全屏页',
            //     "url": '/tbmobile/short-video-fullpage',
            //     "desc": '视频ID在千牛端内容管理获取或者 光合平台 作品管理获取。全屏页短视频（猜你喜欢短视频）内容定位为导购型短视频。短视频全屏页上下滑，指访客通过短视频全屏页上下滑页面，直接引导进入店铺或商品详情页的流量。通过光合视频生成的入口二维码，让买手进行干预点赞。可以被公域推荐获取手淘推荐的流量',
            //     "tag": [
            //         "配合直通车推广",
            //         "流量大",
            //     ],
            //     "imgCode": "hottj",
            //     "showCode": "tbmobile_short-video-fullpage"
            // },
            {
                "name": '手淘有好价',
                "url": '/tbmobile/tm-nice-price',
                "desc": '生参后台显示访客来源：淘宝免费流量 - 手淘有好价',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tm-nice-price"
            },
            {
                "name": '手淘拍立淘',
                "url": '/tbmobile/tm-plt',
                "desc": '生参后台显示访客来源：手淘拍立淘',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tm-plt"
            },
            {
                "name": '淘宝好货推荐',
                "url": '/tbmobile/tm-nice-goodies',
                "desc": '生参后台显示访客来源：淘内待分类',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tm-nice-goodies"
            },
            {
                "name": '小黑盒卡首屏',
                "url": '/tbmobile/tiny-black-box',
                "desc": '生参后台显示访客来源：天猫小黑盒',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tiny-black-box"
            },
            {
                "name": '猜你喜欢小星星',
                "url": '/tbmobile/guess-your-like-star',
                "desc": '生参后台显示访客来源：淘内免费其他',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_guess-your-like-star"
            },
            {
                "name": '首单优惠礼金卡首页',
                "url": '/tbmobile/first-order-coupon',
                "desc": '首单礼金报名https://tmallxrl.sale.tmall.com/activity/marketActivitys.htm 。 1、精准定向新客； 2、自主选择新客拉新费率； 3、先成交后结算； 4、计入店铺成交； 5、全链路氛围打标。 面向新客的高效拉新工具，宝贝所在类目的商品拉新力指数排名，进行实时赛马和星级打分，将商品分成2-5星，星级越高，商品拉新效能越好',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_first-order-coupon"
            },
            {
                "name": '淘宝吃货',
                "url": '/tbmobile/taobao-foodie',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_taobao-foodie"
            },
            {
                "name": '潮玩世界',
                "url": '/tbmobile/trend-play',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_trend-play"
            },
            {
                "name": '节日指南',
                "url": '/tbmobile/holiday-guide',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_holiday-guide"
            },
            {
                "name": '每平每屋',
                "url": '/tbmobile/piece-house',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_piece-house"
            },
            {
                "name": '我的萌宠',
                "url": '/tbmobile/my-cute-pets',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_my-cute-pets"
            },
            {
                "name": '喵鲜生',
                "url": '/tbmobile/cat-fresh-food',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_cat-fresh-food"
            },
            {
                "name": '潮WOO',
                "url": '/tbmobile/trend-woo',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_trend-woo"
            },
            {
                "name": '酷玩星球',
                "url": '/tbmobile/cool-planet',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_cool-planet"
            },
            {
                "name": '海外直购',
                "url": '/tbmobile/odp',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_odp"
            },
            {
                "name": '全球购',
                "url": '/tbmobile/global-purchase',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_global-purchase"
            },
            {
                "name": '邻家好货手淘',
                "url": '/tbmobile/tm-neighbor-goodies',
                "desc": '生参后台显示访客来源：手淘其他店铺商品详情',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tm-neighbor-goodies"
            },
            {
                "name": '天猫国际',
                "url": '/tbmobile/tmall-international',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tmall-international"
            },
            {
                "name": '天猫奢品',
                "url": '/tbmobile/tmall-luxury',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tmall-luxury"
            },
            {
                "name": '天猫果粉',
                "url": '/tbmobile/tmall-apple-fans',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tmall-apple-fans"
            },
            {
                "name": '天猫榜单',
                "url": '/tbmobile/tmall-list',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tmall-list"
            },
            {
                "name": '天猫造新',
                "url": '/tbmobile/tmall-creation',
                "desc": '',
                "tag": [
                    "配合直通车推广",
                    "流量大",
                ],
                "imgCode": "hottj",
                "showCode": "tbmobile_tmall-creation"
            },
        ],
    },
    {
        "name": "运营工具",
        "url": "/transformData",
        "showCode": "title-yyfzgj",
        "children": [
            {
                "name": "指数换算",
                "url": "/transformData",
                "tag": [
                    "转化指数",
                    "加购指数",
                    "收藏人气",
                ],
                "desc": "多指标指数计算工具，给出数值",
                "imgCode": "zshh",
                "showCode": "transformData",
            },
            {
                "name": "DSR智能计算器",
                "url": "/checkNum/dsrCal",
                "tag": [
                    "自动计算",
                    "一目了然",
                    "精确性",
                ],
                "desc": "自动计算还需要多少单5分的好评",
                "imgCode": "zshh",
                "showCode": "checkNum_dsrCal",
            },
            {
                "name": "淘宝客订单检测",
                "url": "/tbguest",
                "tag": [
                    "杜绝佣金丢失",
                ],
                "desc": "检测订单是否为淘宝客订单",
                "imgCode": "zshh",
                "showCode": "tbguest",
            },
            {
                "name": "淘口令生成",
                "url": "/taokouling",
                "tag": [
                    "无分享人的淘口令",
                    "任意链接",
                ],
                "desc": "万能淘口令可以输入任意链接",
                "imgCode": "taokouling",
                "showCode": "taokouling",
            },
            {
                "name": "宝贝评价分析",
                "url": "/appraise",
                "tag": [
                    "淘宝宝贝评价数据分析",
                    "掌握同行评价数据",
                ],
                "desc": "淘宝宝贝评价数据分析",
                "imgCode": "bbpjfx",
                "showCode": "appraise",
            },
            {
                "name": "TOP20w淘宝词库分析",
                "url": "/lexicon",
                "tag": [
                    "指数高",
                    "流量高",
                    "转化率高",
                ],
                "desc": "查询淘宝搜索下拉框选词",
                "imgCode": "ssxltjc",
                "showCode": "leximgCode",
            },
            {
                "name": "淘宝排名查询",
                "url": "/keyRank",
                "tag": [
                    "指定关键词",
                    "批量查询",
                ],
                "desc": "查询宝贝在关键字下排名",
                "imgCode": "ssxltjc",
                "showCode": "keyRank",
            },
            {
                "name": "免费工具箱",
                "url": "/taokit",
                "tag": [
                    "免费电商工具",
                ],
                "desc": "查询宝贝在关键字下排名",
                "imgCode": "love",
                "showCode": "taokit",
            },
        ],
    },
    {
        "name": "竞品监控",
        "url": "/monitor/competitive",
        "showCode": "title-jpjk",
        "children": [
            {
                "name": "单品详情",
                "url": "/monitor/detail",
                "tag": [
                    "竞品监控详情",
                ],
                "desc": "查询监控商品的详细信息",
                "imgCode": "dpxq",
                "showCode": "monitor_detail",
            },
            {
                "name": "SKU销量监控",
                "url": "/monitor/competitive-sku",
                "tag": [
                    "全面监控竞品",
                    "解析竞品",
                ],
                "desc": "",
                "imgCode": "jpjk",
                "showCode": "monitor_competitive",
            },
            {
                "name": "实时销量监控",
                "url": "/monitor/competitive",
                "tag": [
                    "全面监控竞品",
                    "解析竞品",
                ],
                "desc": "",
                "imgCode": "jpjk",
                "showCode": "monitor_competitive",
            },
            {
                "name": "价格监控",
                "url": "/monitor/competitive-money",
                "tag": [
                    "全面监控竞品",
                    "解析竞品",
                ],
                "desc": "",
                "imgCode": "jpjk",
                "showCode": "monitor_competitive",
            },
            {
                "name": "信息修改历史",
                "url": "/monitor/competitive-record",
                "tag": [
                    "全面监控竞品",
                    "解析竞品",
                ],
                "desc": "",
                "imgCode": "jpjk",
                "showCode": "monitor_competitive",
            },
            {
                "name": "宝贝点击率",
                "url": "/monitor/competitive-click",
                "tag": [
                    "全面监控竞品",
                    "解析竞品",
                ],
                "desc": "",
                "imgCode": "jpjk",
                "showCode": "monitor_competitive",
            },
            {
                "name": "引流词分析",
                "url": "/monitor/competitive-flow",
                "tag": [
                    "全面监控竞品",
                    "解析竞品",
                ],
                "desc": "",
                "imgCode": "jpjk",
                "showCode": "monitor_competitive",
            },
            {
                "name": "开通竞品监控",
                "url": "/monitor/annual-fee",
                "tag": [
                    "开通竞品监控入口",
                ],
                "desc": "开通竞品监控入口",
                "imgCode": "ktjpjk",
                "showCode": "monitor_annual-fee",
            },
            {
                "name": "历史记录",
                "url": "/monitor/record",
                "tag": [
                    "竞品监控历史记录",
                ],
                "desc": "查看竞品监控历史记录",
                "imgCode": "lsjl",
                "showCode": "monitor_record",
            },
        ],
    },
    {
        "name": "购买礼品",
        "url": "/store/list",
        "showCode": "title-lpfh",
        "dataSource": "api", // 数据来源
        "children": [
            {
                "name": "商品列表",
                "url": "/store/list",
                "tag": [],
                "desc": "",
                "imgCode": "splb",
                "showCode": "store_list",
            },
            {
                "name": "订单列表",
                "url": "/store/orderList",
                "tag": [],
                "desc": "",
                "imgCode": "ddlb",
                "showCode": "store_orderList",
            },
            {
                "name": "包裹列表",
                "url": "/store/package",
                "tag": [],
                "desc": "",
                "imgCode": "bglb",
                "showCode": "store_package",
            },
        ],
    },
    // {
    //     "name": "流量中心",
    //     "url": "/flow/taobao",
    //     "showCode": "title-llzx",
    //     "children": [
    //         {
    //             "name": "淘宝流量提升",
    //             "url": "/flow/taobao",
    //             "tag": [
    //                 "淘宝",
    //                 "数据提升",
    //                 "流量资源",
    //             ],
    //             "desc": "",
    //             "imgCode": "tbllts",
    //             "showCode": "flow_taobao",
    //         },
    //         {
    //             "name": "拼多多流量提升",
    //             "url": "/flow/pdd",
    //             "tag": [
    //                 "拼多多",
    //                 "数据提升",
    //                 "流量资源",
    //             ],
    //             "desc": "",
    //             "imgCode": "pddllts",
    //             "showCode": "flow_pdd",
    //         },
    //         {
    //             "name": "京东流量提升",
    //             "url": "/flow/jd",
    //             "tag": [
    //                 "京东",
    //                 "数据提升",
    //                 "流量资源",
    //             ],
    //             "desc": "",
    //             "imgCode": "jdllts",
    //             "showCode": "flow_jd",
    //         },
    //         {
    //             "name": "抖音流量提升",
    //             "url": "/flow/dy",
    //             "tag": [
    //                 "抖音",
    //                 "数据提升",
    //                 "流量资源",
    //             ],
    //             "desc": "",
    //             "imgCode": "dyllts",
    //             "showCode": "flow_dy",
    //         },
    //         {
    //             "name": "任务列表",
    //             "url": "/flow/tasklist",
    //             "tag": [
    //                 "各平台发布任务查看",
    //                 "精确定位",
    //             ],
    //             "desc": "",
    //             "imgCode": "rwlb",
    //             "showCode": "flow_tasklist",
    //         },
    //     ],
    // },
    {
        // 注意: API接口都是跳转 doc 文档的
        "name": "API接口",
        "url": "http://doc.vv-tool.com/taobao",
        "showCode": "title-apijk",
        "dataSource": "api", // 数据来源
        "children": [
            {
                "name": "电商工具接口",
                "url": "/taobao",
                "tag": [],
                "desc": "",
                "imgCode": "apitool",
                "showCode": "dsgjjk_taobao",
            },
            {
                "name": "物流发货接口",
                "url": "/logistics",
                "tag": [],
                "desc": "",
                "imgCode": "apitool",
                "showCode": "dsgjjk_logistics",
            },
            {
                "name": "全平台ERP接口",
                "url": "/platformErp",
                "tag": [],
                "desc": "",
                "imgCode": "apitool",
                "showCode": "dsgjjk_platformErp",
            },
            {
                "name": "更多实用接口",
                "url": "/taobao",
                "tag": [],
                "desc": "",
                "imgCode": "apitool",
                "showCode": "gdsyjk_taobao",
            },
        ],
    },
    {
        "name": "用户中心",
        "url": "/personCenter",
        "showCode": "title-yonghuzx",
        "children": [
            {
                "name": "账户中心",
                "url": "/personCenter",
                "tag": [],
                "desc": "",
                "imgCode": "zhzx",
                "showCode": "personCenter",
            },
            {
                "name": "消息中心",
                "url": "/newsCenter",
                "tag": [],
                "desc": "",
                "imgCode": "xxzx",
                "showCode": "newsCenter",
            },
            {
                "name": "开通会员",
                "url": "/vip",
                "tag": [],
                "desc": "",
                "imgCode": "kthy",
                "showCode": "vip",
            },
            {
                "name": "推广管理",
                "url": "/promotion-manage",
                "tag": [],
                "desc": "",
                "imgCode": "kthy",
                "showCode": "promotion_manage",
            },
            {
                "name": "业务查询",
                "url": "/busQuery",
                "tag": [],
                "desc": "",
                "imgCode": "ywcx",
                "showCode": "busQuery",
            },
            {
                "name": "充值记录",
                "url": "/recharge",
                "tag": [],
                "desc": "",
                "imgCode": "czjl",
                "showCode": "recharge",
            },
            // {
            //     "name": "店铺管理",
            //     "url": "/storeManage",
            //     "tag": [],
            //     "desc": "",
            //     "imgCode": "dpgl",
            //     "showCode": "storeManage",
            // },
        ],
    },
];
